import { NavLink, useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import '../../assets/scss/form.scss';
import usePost from '../../hooks/usePost';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const ForgetPassword = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState('');
  const { loading, success, error, postData } = usePost(
    '/forget-password'
  );
  const handleSubmit = () => {
    postData({
      body: {
        email,
      },
    });
  };

  useEffect(() => {
    if (success) {
      Swal.fire({
        confirmButtonText: 'Back to Log in',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
        },
        showClass: { popup: '' },
        hideClass: { popup: '' },
        html: ` 
				<div class="fz-20 fw-800 form-title">
          	<b>Reset your password</b>
        </div>
        <div class="text-left mt-20 fw-500"> 
          A password reset link has been sent to your email. The password reset link will automatically expire after <span class='color-red'>30 minutes.</span> Please click the link in your mailbox to modify your password within <span class='color-red'>30 minutes.</span>.
        </div>
    `,
      }).then(() => {
        nav('/sign-in');
      });
    }
  }, [success]);
  return (
    <div className="container">
      <div className="form-container ">
        <div className="w-70 margin-auto ">
          <div className="page-title fz-30">
            Forget Password
          </div>
          <p className="mt-20 text-center fz-14 color-gray">
            Enter your registered e-mail. The system will
            email you your account information shortly.
          </p>
          <div className="w-50 margin-auto">
            <p className="fz-16 mt-40">Email</p>
            <Input
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              invalid={error}
            />
            <span className="fz-12 color-red">{error}</span>
          </div>

          <p className="mt-40 text-center fz-14 color-gray">
            If you can&apos;t remember the e-mail address
            you registered with, please contact us.
          </p>
          <p className="mt-10 text-center fz-14 color-gray">
            Has your email changed?
            <br /> If you no longer use the email address
            associated with your APSAVD 2025 Congress
            account, you may contact us for help restoring
            access to your account.
          </p>

          <button
            className="primary-btn fw-800 mt-40"
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
          <NavLink
            style={{ display: 'block' }}
            className="mt-40 fz-14 text-center hover color-blue"
            to={'/sign-in'}
          >
            ⭠ Back to Log in
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
