/* eslint-disable no-undef */
import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

const usePost = url => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const { token } = useContext(AuthContext);
  const [status, setStatus] = useState(null);

  const postData = async ({
    body,
    options = {},
    endpoint = '',
  }) => {
    setLoading(true);

    try {
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}${endpoint || url}`,
        {
          ...options,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
          },
        }
      );

      setStatus(response.status);

      if (response.ok) {
        setSuccess(true);
        setData(await response.json());
      } else {
        const res = await response.json();
        setError(res.message);
      }
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setError(null);
    setSuccess(false);
    setData(null);
    setStatus(null);
  };

  return {
    postData,
    loading,
    error,
    success,
    data,
    handleReset,
    status,
  };
};

export default usePost;
