import { useContext, useEffect, useState } from 'react';
import SubmissionForm from '../../components/submission/SubmissionForm';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePost from '../../hooks/usePost';
import Swal from 'sweetalert2';
import { AuthContext } from '../../context/AuthContext';

const SubmissionEdit = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { data } = useFetch(`/submission/${id}`);
  const {
    loading,
    success,
    error,
    postData: updateSubmission,
    handleReset,
  } = usePost(`/submission/${id}`);
  const { token, isLogin } = useContext(AuthContext);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      abstractTitle: data?.data?.abstractTitle,
      category: data?.data?.category,
      presentationType: data?.data?.presentationType?.value,
      yia: data?.data?.yia,
      cvFile: data?.data?.cvFile,
      institute: '',
      instituteList: JSON.parse(
        data?.data?.instituteList || '[]'
      ),
      authorList: JSON.parse(
        data?.data?.authorList || '[]'
      ),
      content: {
        background: data?.data?.content?.background || '',
        method: data?.data?.content?.method || '',
        results: data?.data?.content?.results || '',
        conclusion: data?.data?.content?.conclusion || '',
      },
    },
    validationSchema: yup.object().shape({
      abstractTitle: yup.string().required(),
      category: yup.object().nullable().required(),
      presentationType: yup.string().required(),
      authorList: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
          instituteList: yup.array().min(1, ''),
        })
      ),
      content: yup.object().shape({
        background: yup.string().required(),
        method: yup.string().required(),
        results: yup.string().required(),
        conclusion: yup.string().required(),
      }),
      cvFile: yup
        .mixed()
        .nullable()
        .when('yia', {
          is: val => val === 'Attend',
          then: () => yup.mixed().required(),
          otherwise: () => yup.mixed().nullable(),
        }),
    }),
    onSubmit: () => {
      if (!loading) {
        const originData = data.data;
        const body = {};
        Object.entries(values).forEach(([key, val]) => {
          if (
            originData[key] !== values[key] &&
            key !== 'authorList' &&
            key !== 'instituteList' &&
            key !== 'cvFile'
          ) {
            // console.log('?');
            body[key] = val;
          } else if (
            key === 'authorList' ||
            key === 'instituteList'
          ) {
            if (
              JSON.stringify(values[key]) !==
              originData[key]
            ) {
              body[key] = JSON.stringify(val);
            }
          }
        });
        // console.log(values.content === originData.content);
        // console.log(body);
        updateSubmission({
          body,
        });
      }
    },
  });

  const handleDeleteFile = async () => {
    if (data?.data?.cvFile) {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/delete-file`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              url: data.data.cvFile.url,
            }),
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleFileUpload = async () => {
    try {
      await handleDeleteFile();
      const formData = new FormData();
      formData.append('postId', id);
      formData.append('type', 'CV');
      const filename = `CV_File.${values.cvFile.name.split('.').pop().toLowerCase()}`;
      const file = new File(
        [values.cvFile],
        filename.replace(/\s+/g, '_'),
        {
          type: values.cvFile.type,
        }
      );
      formData.append('file', file);
      console.log(values.cvFile);
      await fetch(
        `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      )
        .then(response => {
          if (response.ok) {
            nav('/submission');
          }
        })
        .catch(e => console.error(e));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (success) {
      if (
        typeof values.cvFile === 'object' &&
        values.cvFile
      ) {
        handleReset();
        handleFileUpload();
      } else {
        nav('/submission');
      }
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        text: error,
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      }).then(() => {
        handleReset();
      });
    }
  }, [error]);

  // test
  useEffect(() => {
    if (!isLogin) {
      nav('/sign-in');
    }
  }, [isLogin]);
  return (
    <div className="container">
      <SubmissionForm
        id={id}
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default SubmissionEdit;
