import useFetch from '../hooks/useFetch';

const CorporationLogo = () => {
  const { data } = useFetch('/corporation/logo');
  // console.log(data);
  return (
    <div className="container">
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default CorporationLogo;
