import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './components/Layout';
import VerifyEmailToken from './pages/auth/SignUp/VerifyEmailToken';
import SignUp from './pages/auth/SignUp/SignUp';
import SignIn from './pages/auth/SignIn';
import News from './components/news/News';
import NewsList from './components/news/NewsList';
import CustomPage from './pages/CustomPage';
import SubmissionInstructions from './pages/submission/SubmissionInstructions';
import SubmissionCreate from './pages/submission/SubmissionCreate';
import SubmissionList from './pages/submission/SubmissionList';
import SubmissionEdit from './pages/submission/SubmissionEdit';
import SubmissionPreview from './pages/submission/SubmissionPreview';
import Speaker from './pages/Speaker';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Join from './pages/join/Join';
import Register from './pages/join/Register';
import Payment from './pages/join/Payment';
import Result from './pages/join/Result';
import Membership from './pages/auth/Membership';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: '/news',
        element: <NewsList />,
      },
      {
        path: 'news/:id',
        element: <News />,
      },
      {
        path: 'page/:id',
        element: <CustomPage />,
      },
      {
        path: '/invited-speakers',
        element: <Speaker />,
      },
      {
        path: '/verify-email-token/:token',
        element: <VerifyEmailToken />,
      },
      {
        path: '/submission/instructions',
        element: <SubmissionInstructions />,
      },
      {
        path: '/submission/create',
        element: <SubmissionCreate />,
      },
      {
        path: '/submission',
        element: <SubmissionList />,
      },
      {
        path: '/submission/:id',
        element: <SubmissionEdit />,
      },
      {
        path: '/forget-password',
        element: <ForgetPassword />,
      },
      {
        path: '/reset-password/:token',
        element: <ResetPassword />,
      },
      {
        path: '/join',
        element: <Join />,
      },
      {
        path: '/join/register',
        element: <Register />,
      },
      {
        path: '/payment/:id',
        element: <Payment />,
      },
      {
        path: '/join/result/:id',
        element: <Result />,
      },
      {
        path: '/membership',
        element: <Membership />,
      },
    ],
  },
  {
    path: 'submission/preview/:id',
    element: <SubmissionPreview />,
  },
]);

export default router;
