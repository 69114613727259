import './simpleLayout.scss';

const SimpleLayout = ({ title, content }) => {
  return (
    <div className="container">
      <div className="page-title fz-30">{title}</div>
      <div
        className="page-content-box"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
};

export default SimpleLayout;
