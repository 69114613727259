import {
  Document,
  Page,
  Text,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ReactDOMServer from 'react-dom/server';

const htmlToReactPdf = content => {
  // 替換 <strong> 標籤為加粗樣式
  const boldFont = content.replace(
    /<strong>(.*?)<\/strong>/g,
    (_, content) => {
      return `<b style="font-family: Times-Bold">${content}</b>`;
    }
  );
  const italicFont = boldFont.replace(
    /<em>(.*?)<\/em>/g,
    (_, content) => {
      return `<i style="font-family: Times-Italic">${content}</i>`;
    }
  );
  return italicFont;
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1,
    marginVertical: 0,
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
  },
  author: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
    marginBottom: 20,
    marginTop: 20,
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    marginBottom: -12,
  },
  context: {
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  text: {
    margin: 0,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  bold: {
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 400,
  },
});

const Content = ({ data }) => {
  const Authors = () => {
    return (
      <>
        {JSON.parse(data.authorList || '[]').map(
          (author, idx) => (
            <>
              <sup
                style={{
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              >
                {author.instituteList.map(
                  (val, j) =>
                    JSON.parse(
                      data.instituteList || '[]'
                    ).findIndex(
                      ele => ele.value === val.value
                    ) +
                    1 +
                    (j < author.instituteList.length - 1
                      ? ','
                      : '')
                )}
              </sup>
              <span key={idx}>
                {author.name}
                {idx < data.authorList.length - 1
                  ? ', '
                  : ''}
              </span>
            </>
          )
        )}
      </>
    );
  };

  const authorList = ReactDOMServer.renderToStaticMarkup(
    <Authors />
  );

  const Organizations = () => {
    return (
      <div
        style={{ marginTop: '-15px', marginBottom: '20px' }}
      >
        {JSON.parse(data.instituteList || '[]').map(
          (institute, idx) => (
            <div
              key={idx}
            >{`${idx + 1} ${institute.label}`}</div>
          )
        )}
      </div>
    );
  };

  const organizationList =
    ReactDOMServer.renderToStaticMarkup(<Organizations />);

  return (
    <Document>
      <Page style={styles.body}>
        {/* <Text style={styles.header} fixed>
		~ Created with react-pdf ~
	</Text> */}
        <Text style={styles.title}>
          {data.abstractTitle}
        </Text>

        <Html style={styles.author}>{authorList}</Html>
        <Html style={styles.text}>{organizationList}</Html>
        {data.content.background && (
          <Text style={styles.subtitle}>Background</Text>
        )}
        <Html style={styles.context}>
          {htmlToReactPdf(data.content.background)}
        </Html>
        {data.content.method && (
          <Text style={styles.subtitle}>Method</Text>
        )}
        <Html style={styles.context}>
          {htmlToReactPdf(data.content.method)}
        </Html>
        {data.content.results && (
          <Text style={styles.subtitle}>Results</Text>
        )}

        <Html style={styles.context}>
          {htmlToReactPdf(data.content.results)}
        </Html>
        {data.content.conclusion && (
          <Text style={styles.subtitle}>Conclusion</Text>
        )}

        <Html style={styles.context}>
          {htmlToReactPdf(data.content.conclusion)}
        </Html>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const Preview = ({ data, isDownload = false }) => {
  return !isDownload ? (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <Content data={data} />
    </PDFViewer>
  ) : (
    <Content data={data} />
  );
};

export default Preview;
