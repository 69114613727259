import { useContext, useEffect } from 'react';
import PersonalInformation from '../../components/auth/PersonalInformation';
import { AuthContext } from '../../context/AuthContext';
import { useFormik } from 'formik';
import Collapse from '../../components/Collapse';
import FileDropzone from '../../components/FileDropzone';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import * as yup from 'yup';
import usePost from '../../hooks/usePost';
import Swal from 'sweetalert2';
import useFetch from '../../hooks/useFetch';
import '../../assets/scss/form.scss';

const ForeignPriceList = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
}) => {
  const { data } = useFetch(
    '/registration-type?type=Foreign'
  );
  const handleRowClick = item => {
    setFieldValue('registrationType', item);
  };
  return (
    <div className="table-responsive">
      <table className="registration-form-style">
        <thead>
          <tr>
            <th></th>
            <th>Categories</th>
            <th>
              Early-bird <br />
            </th>

            <th>Standard</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map(item => (
            <tr
              key={item.value}
              className={
                values.registrationType === item
                  ? 'selected-row'
                  : ''
              }
              onClick={() => handleRowClick(item)}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  values.registrationType === item
                    ? '#F5F5F5'
                    : 'transparent',
              }}
            >
              <td>
                <div className="radio-group">
                  <div className="radio-label">
                    <input
                      type="radio"
                      checked={
                        values.registrationType === item
                      }
                      onChange={() =>
                        setFieldValue(
                          'registrationType',
                          item
                        )
                      }
                    />
                  </div>
                </div>
              </td>
              <td>
                <span>{item.label}</span>
              </td>
              <td>
                {item.label === 'Member' && 'USD 400'}
                {item.label === 'Non-Member' && 'USD 500'}
                {item.label === 'Allied/Nurse/Trainee' &&
                  'USD 300'}
              </td>
              <td>
                {item.label === 'Member' && 'USD 500'}
                {item.label === 'Non-Member' && 'USD 600'}
                {item.label === 'Allied/Nurse/Trainee' &&
                  'USD 350'}
              </td>
            </tr>
          ))}
          <tr>
            {values.registrationType?.label ===
              'Member' && (
              <td colSpan={5}>
                <label>Email</label>
                <span className="require">*</span>
                <Input
                  id="memberNumber"
                  onChange={handleChange}
                  value={values.memberNumber}
                  invalid={
                    errors.memberNumber &&
                    touched.memberNumber
                  }
                />
              </td>
            )}
            {values.registrationType?.label ===
              'Allied/Nurse/Trainee' && (
              <td colSpan={5}>
                <b>Proof of Identity</b>
                <p>
                  To register as Allied/Nurse/Trainee,
                  please upload your Proof Document of
                  Identity for recognition, and any
                  unidentified proof will be requested to
                  make up to the full payment.
                </p>
                <FileDropzone
                  maxSize={10}
                  acceptType={{
                    '.png': [],
                    '.jpg': [],
                    '.jpeg': [],
                  }}
                  field={'uploadFile'}
                  setFieldValue={setFieldValue}
                  file={values.uploadFile}
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TaiwanesePriceList = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
}) => {
  const { data } = useFetch(
    '/registration-type?type=Taiwanese'
  );
  const handleRowClick = item => {
    setFieldValue('registrationType', item);
  };
  return (
    <div className="table-responsive">
      <table className="registration-form-style">
        <thead>
          <tr>
            <th></th>
            <th>Categories</th>
            <th>報名費</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map(item => (
            <tr
              key={item.value}
              className={
                values.registrationType === item
                  ? 'selected-row'
                  : ''
              }
              onClick={() => handleRowClick(item)}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  values.registrationType === item
                    ? '#F5F5F5'
                    : 'transparent',
              }}
            >
              <td>
                <div className="radio-group">
                  <div className="radio-label">
                    <input
                      type="radio"
                      checked={
                        values.registrationType === item
                      }
                      onChange={() =>
                        setFieldValue(
                          'registrationType',
                          item
                        )
                      }
                    />
                  </div>
                </div>
              </td>
              <td>
                <span>{item.label}</span>
              </td>
              <td>
                <span>{`NTD ${item.fee}`}</span>
              </td>
            </tr>
          ))}
          <tr>
            {values.registrationType?.value === 2455 && (
              <td colSpan={4}>
                <label>Email</label>
                <span className="require">*</span>
                <Input
                  id="memberNumber"
                  onChange={handleChange}
                  value={values.memberNumber}
                  invalid={
                    errors.memberNumber &&
                    touched.memberNumber
                  }
                />
              </td>
            )}
            {(values.registrationType?.value === 2457 ||
              values.registrationType?.value === 2458) && (
              <td colSpan={4}>
                <b>Proof of Identity</b>
                <p>
                  To register as Allied/Nurse/Trainee,
                  please upload your Proof Document of
                  Identity for recognition, and any
                  unidentified proof will be requested to
                  make up to the full payment.
                </p>
                <FileDropzone
                  maxSize={10}
                  acceptType={{
                    '.png': [],
                    '.jpg': [],
                    '.jpeg': [],
                  }}
                  field={'uploadFile'}
                  setFieldValue={setFieldValue}
                  file={values.uploadFile}
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Register = () => {
  const nav = useNavigate();
  const { user, checkTokenValidity, token, onUpdateUser } =
    useContext(AuthContext);
  const {
    success,
    error,
    postData,
    handleReset,
    data,
    status,
    loading,
  } = usePost('/register');
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...user,
      title: {
        label: user?.title,
      },
      country: {
        label: user?.country,
      },
      registrationType: '',
      memberNumber: user?.email,
      dietaryRequest: '',
      step: 0,
      uploadFile: null,
      countryCode: {
        label: user?.countryCode,
      },
    },
    validationSchema: yup.object().shape({
      registrationType: yup.object().shape({
        label: yup.string().required(),
      }),
      memberNumber: yup.string().when('registrationType', {
        is: () =>
          values.registrationType.label === 'APSAVD Member',
        then: () => yup.string().required(),
        otherwise: () => yup.string().nullable(),
      }),
      dietaryRequest: yup.string().required(),
      // uploadFile: yup.mixed().when('registrationType', {
      //   is: () =>
      //     values.registrationType.label ===
      //     'Trainee/Nurse/Student',
      //   then: () => yup.mixed().required(),
      //   otherwise: () => yup.mixed().nullable(),
      // }),
    }),
    onSubmit: () => {
      const body = {
        registrationType: values.registrationType.value,
        memberNumber: values.memberNumber,
        dietaryRequest: values.dietaryRequest,
      };

      postData({
        body,
      });
    },
  });

  useEffect(() => {
    if (token) {
      checkTokenValidity();
    } else {
      nav('/sign-in');
    }
  }, [token]);

  const handleUploadFile = ({ type, file, id }) => {
    const formData = new FormData();
    formData.append('postId', id);
    formData.append('type', type);
    formData.append('file', file);
    // console.log(file);
    return fetch(
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
  };

  useEffect(() => {
    if (success && data) {
      const id = data.data.id;
      if (+data.data.amount > 0) {
        if (!values.uploadFile) {
          onUpdateUser();
          nav(`/payment/${id}`);
        } else {
          handleUploadFile({
            file: values.uploadFile,
            id,
            type: 'Proof of Identity',
          })
            .then(() => {
              nav(`/payment/${id}`);
              onUpdateUser();
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        nav(`/join/result/${id}`);
      }
    }
  }, [success, data]);

  useEffect(() => {
    if (user?.registrationId) {
      Swal.fire({
        text: 'Your account has been registered',
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      }).then(() => {
        handleReset();
        nav('/membership?toBottom=1');
      });
    }
  }, [user?.registrationId]);

  useEffect(() => {
    if (error && status) {
      if (status !== 302) {
        Swal.fire({
          text: error,
          showClass: { popup: '' },
          hideClass: { popup: '' },
          showConfirmButton: true,
          customClass: {
            confirmButton: 'swal-btn swal-confirm',
          },
          html: `
						<p class="text-left fz-16">
							${error}, please verify and try again or contact:
						</p>
							 <div class="text-left mt-20 fz-14"> 
						APSAVD 2025 Congress Registration & Submission
						Office
					</div>
						 <div class="text-left fz-14">
						Email:    
					<a href="mailto:registration@apsavd2025.tw">registration@apsavd2025.tw</a>
					</div>
						<div class="text-left fz-14">
						Website:
						<a href="https://apsavd2025.tw/">
							https://apsavd2025.tw/
						</a>
					</div>
					`,
        }).then(() => {
          handleReset();
        });
      } else {
        Swal.fire({
          text: error,
          showClass: { popup: '' },
          hideClass: { popup: '' },
          showConfirmButton: true,
          customClass: {
            confirmButton: 'swal-btn swal-confirm',
          },
        }).then(() => {
          handleReset();
          nav('/membership?toBottom=1');
        });
      }
    }
  }, [error, status]);

  const handleCancel = () => {
    Swal.fire({
      text: 'Would you kindly confirm if you would like to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        nav(-1);
      }
    });
  };

  return (
    <div className="container">
      <div className="page-title fz-30">Registration</div>
      <div className="form-container">
        <Collapse
          title={'Personal Information'}
          requiredText="* = required field"
          isDefaultOpen={true}
        >
          <PersonalInformation
            isDisabled={true}
            values={values}
            showEmailTooltip={true}
          />
        </Collapse>
        <Collapse
          className={`mt-40 ${
            (errors.registrationType &&
              touched.registrationType) ||
            (errors.uploadFile && touched.uploadFile)
              ? 'error-border'
              : ''
          }`}
          title={'Registration Information'}
          isRequired={true}
          isDefaultOpen={true}
        >
          {user?.country === 'Taiwan' ? (
            <TaiwanesePriceList
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
          ) : (
            <ForeignPriceList
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
          )}
        </Collapse>
        <Collapse
          className={`mt-40 ${
            errors.dietaryRequest && touched.dietaryRequest
              ? 'error-border'
              : ''
          }`}
          title={'Dietary Request'}
          isRequired={true}
          isDefaultOpen={true}
          // requiredText={'(Invitation-Only Gala Dinner)'}
          remark={
            values?.country?.label === 'Taiwan' &&
            values?.registrationType.label ===
              'Presenting Author'
              ? '(Invitation-Only Gala Dinner)'
              : ''
          }
        >
          <div className="radio-group p-20">
            <label className="radio-label">
              <input
                type="radio"
                value={'No Restriction'}
                checked={
                  values.dietaryRequest === 'No Restriction'
                }
                onChange={e =>
                  setFieldValue(
                    'dietaryRequest',
                    e.target.value
                  )
                }
              />
              <span>No Restriction</span>
            </label>
            <label className="radio-label">
              <input
                type="radio"
                value={'Vegetarian'}
                checked={
                  values.dietaryRequest === 'Vegetarian'
                }
                onChange={e =>
                  setFieldValue(
                    'dietaryRequest',
                    e.target.value
                  )
                }
              />
              <span>Vegetarian</span>
            </label>
          </div>
        </Collapse>
        <div className="button-group">
          <button
            className="secondary-btn fw-800 mt-40"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn fw-800 mt-40"
            onClick={handleSubmit}
            disabled={loading}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
