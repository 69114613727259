import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import '../../assets/scss/form.scss';
import Swal from 'sweetalert2';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';

const SubmissionInstructions = () => {
  const nav = useNavigate();
  const { isLogin } = useContext(AuthContext);

  useEffect(() => {
    const hasAgreed = localStorage.getItem(
      'submissionInstructionsAgreed'
    );

    if (!hasAgreed) {
      Swal.fire({
        html: `
          <div class="fz-20 fw-800 form-title">
            <b>Copyright & Legal Obligation</b>
          </div>
          <div class="text-left">
            <p class="fw-500">
              • All information in the abstract submission, including authors’ names, affiliations, authors order of listing, and the content of abstract, will be used in the congress publications.
            </p>
            <p class="mt-20 fw-500">
              • The contributor carries legal obligations of the abstract; any violation of a third party’s right will be the responsibility of the contributor him/herself.
            </p>
            <p class="mt-20 fw-500">
              • APSAVD 2025 Congress has the copyright to use the abstract in the congress for scientific purposes without any prior notice to the contributor/authors.
            </p>
          </div>
            <label class="custom-checkbox">
              <input type="checkbox" id="agreeCheckbox" />
              <span class="checkbox-custom"></span>
              <span class="fw-500">I have read and understood the above.</span>
            </label>
						  <div id="error-message" class="fz-14 color-red text-left"></div>
        `,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Submit the Abstract',
        showClass: { popup: '' },
        hideClass: { popup: '' },
        preConfirm: () => {
          const checkbox =
            document.getElementById('agreeCheckbox');
          const errorMessage =
            document.getElementById('error-message');

          if (!checkbox.checked) {
            errorMessage.textContent =
              'You must agree to continue.';
            return false;
          }
          return true;
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
          cancelButton: 'swal-btn swal-cancel',
        },
      }).then(result => {
        if (result.isConfirmed) {
          localStorage.setItem(
            'submissionInstructionsAgreed',
            'true'
          );
        } else {
          nav('/');
        }
      });
    }
  }, [nav]);

  return (
    <div className="container">
      <div className="page-title fz-30">
        Abstract Submission
      </div>
      <div className="form-container">
        <div className="fz-20 fw-800 form-title">
          Author Instructions
        </div>
        <b>Important Date for the Author</b>
        <div className="card-group">
          <Card className={'fw-800 text-center'}>
            Abstract Submission Opens
            <p className="color-red">March 5, 2025</p>
          </Card>
          <Card className={'fw-800 text-center'}>
            Deadline for Abstract Submission
            <p className="color-red">
              23:59, June 30, 2025 (GMT+8)
            </p>
          </Card>
          <Card className={'fw-800 text-center'}>
            Notification of Abstract Audit Result
            <p className="color-red">
              July 20, 2025 (GMT+8)
            </p>
          </Card>
        </div>
        <Card className={'text-center'}>
          Presenting author of an accepted abstract should
          register and complete the full payment prior to
          <span className="color-red fw-800">
            {' '}
            23:59, August 15, 2025 (GMT+8)
          </span>
          , in order to be included in the program of APSAVD
          2025 Congress.
        </Card>
        <div className="mt-40">
          <b>Format for submitting Abstract(s)</b>
        </div>
        <p className="mx-10">
          • Please follow below instructions to assure a
          correct and complete submission.
        </p>
        <p className="mx-10">
          • The author should specify the category to which
          he/she is submitting.
        </p>
        <p className="mx-10">
          • The authors’ name and hospital affiliations
          should not appear in the submitting file, so blind
          review could be processed.
        </p>
        <button
          className="primary-btn fw-800 mt-40"
          onClick={() => {
            if (isLogin) {
              nav('/submission');
            } else {
              nav('/sign-in');
            }
          }}
        >
          Next
        </button>
      </div>{' '}
    </div>
  );
};

export default SubmissionInstructions;
