import './news.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const News = ({ news }) => {
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 576
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="news-container">
      <div className="fz-30 fw-800">LATEST NEWS</div>
      <div className="news-btn">
        <div className="news-line">
          <div onClick={() => nav('/news')}>
            <img
              src={
                isMobile
                  ? `${process.env.PUBLIC_URL}/images/home-news-btn-m.png`
                  : `${process.env.PUBLIC_URL}/images/home-news-btn.png`
              }
              alt="Button"
              onMouseEnter={e => {
                if (!isMobile) {
                  e.currentTarget.src = `${process.env.PUBLIC_URL}/images/home-news-btn-hover.png`;
                }
              }}
              onMouseLeave={e => {
                if (!isMobile) {
                  e.currentTarget.src = `${process.env.PUBLIC_URL}/images/home-news-btn.png`;
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="news-list-container">
        <div className="news-list">
          {news?.map(data => (
            <div
              key={data.id}
              onClick={() => nav(`/news/${data.id}`)}
              className="news-item"
            >
              <span className="fz-17">
                {moment(
                  data.start_datetime,
                  'YYYYMMDD'
                ).format('YYYY/MM/DD')}
              </span>
              <div className="fz-21">{data.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
