import QuickLinks from '../components/home/QuickLinks';
import KeyDates from '../components/home/KeyDates';
import News from '../components/home/News';
import Footer from '../components/footer/Footer';
import WelcomeMessage from '../components/home/WelcomeMessage';
import Sponsors from '../components/home/Sponsors';
import useFetch from '../hooks/useFetch';

const Home = () => {
  const { data: homePageData } = useFetch('/page/home', {
    method: 'GET',
  });

  return (
    <>
      <div className="bg">
        <section className="container home-info mt-40">
          <QuickLinks
            quickLinks={homePageData?.quickLink}
          />
          <News news={homePageData?.news} />
          <KeyDates keyDates={homePageData?.keyDate} />
        </section>
        <WelcomeMessage />
        <Sponsors />
      </div>
      <Footer />
    </>
  );
};

export default Home;
