import { useFormik } from 'formik';
import Input from '../Input';
import ErrorMessage from '../ErrorMessage';
import '../../assets/scss/form.scss';
import '../auth/SignInForm.scss';
import * as yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';

const SignInForm = () => {
  const nav = useNavigate();
  const { login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('apsavd_username_status') ===
      'remembered'
  );
  const [validatedError, setValidatedError] = useState('');

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      username: isChecked
        ? localStorage.getItem('apsavd_username')
        : '',
      password: '',
    },
    validationSchema: yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: async rest => {
      setIsLoading(true);

      const response = await login(rest);

      if (response === 'OK') {
        nav('/');
      } else {
        setValidatedError(response);
      }
      // login
      setIsLoading(false);
    },
  });

  useEffect(() => {
    localStorage.setItem(
      'apsavd_username_status',
      isChecked ? 'remembered' : ''
    );
  }, [isChecked]);
  return (
    <form
      className="form-container"
      onSubmit={handleSubmit}
    >
      <div className="page-title fz-30">Log In</div>
      <div className="form-item margin-auto w-30 mt-20">
        <label>Email</label>
        <ErrorMessage
          error={errors.username}
          touched={touched.username}
          value={values.username}
        />
        <Input
          type="text"
          id="username"
          value={values.username}
          onChange={handleChange}
          invalid={errors.username && touched.username}
        />
      </div>
      <div className="form-item margin-auto w-30 mt-40">
        <label>Password</label>
        <ErrorMessage
          error={errors.password}
          touched={touched.password}
          value={values.password}
        />
        <div style={{ position: 'relative' }}>
          <Input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={values.password}
            onChange={handleChange}
            invalid={errors.password && touched.password}
          />
          <span
            className="auth-pwd-btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <IoEyeOff /> : <IoEye />}
          </span>
        </div>
        {validatedError && (
          <span className="fz-12 color-red">
            {validatedError}
          </span>
        )}
        <div className="auth-fgpwd mt-20">
          <div
            className="checkbox-group"
            onClick={() => {
              setIsChecked(prev => !prev);
            }}
          >
            <div
              className={`checkbox-style ${isChecked ? 'checked' : ''}`}
            >
              {isChecked && <FaCheck />}
            </div>
            <span className="fz-14">Remember me</span>
          </div>
          <NavLink
            to="/forget-password"
            className="text-right fz-14 hover"
          >
            <u>Forgot your password</u>
          </NavLink>
        </div>
      </div>
      <div className="form-item margin-auto w-30">
        <button
          className="primary-btn fw-800 mt-40"
          disabled={isLoading}
        >
          Log In
        </button>
        <div className="text-center mt-20">
          Don&apos;t have an account?
          <br />
          <NavLink to={'/sign-up'} className="hover">
            <u>Create one</u>
          </NavLink>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
