import { NavLink, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import usePost from '../../hooks/usePost';
import { IoEye } from 'react-icons/io5';

import '../../assets/scss/submission.scss';
import moment from 'moment/moment';
import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AuthContext } from '../../context/AuthContext';

import { pdf } from '@react-pdf/renderer';
import Preview from '../../components/submission/Preview';

const SubmissionList = () => {
  const { token, isLogin } = useContext(AuthContext);
  const nav = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { data, loading, fetchData } =
    useFetch('/submission');

  const { postData, success, handleReset, error } = usePost(
    '/submission/status'
  );

  const getPDF = async id => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/submission/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      // console.log(data);

      const blob = await pdf(
        <Preview data={data.data} isDownload={true} />
      ).toBlob();

      const file = new File(
        [blob],
        `submission_${id}.pdf`,
        {
          type: 'application/pdf',
        }
      );
      return file;
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteSubmission = ({ id, status }) => {
    Swal.fire({
      text: 'Are you sure you would like to delete it?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        postData({
          body: {
            id,
            status,
          },
        });
      }
    });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data?.success && !loading) {
      const newItems = data?.data?.map(d => ({
        ...d,
        icons: {
          delete: `${process.env.PUBLIC_URL}/images/submission-icon-delete.svg`,
          edit: `${process.env.PUBLIC_URL}/images/submission-icon-edit.svg`,
          download: `${process.env.PUBLIC_URL}/images/submission-icon-download.svg`,
          previewColor: '#B50004',
        },
      }));
      setItems(newItems);
    }
  }, [data, loading]);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth < 1024 && !isSmallScreen) {
        setIsSmallScreen(true);
        Swal.fire({
          text: 'For the best experience, please access this site on a desktop device.',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showClass: { popup: '' },
          hideClass: { popup: '' },
        });
      } else if (
        window.innerWidth >= 1024 &&
        isSmallScreen
      ) {
        setIsSmallScreen(false);
        Swal.close();
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [isSmallScreen]);

  const handleSubmit = ({ id }) => {
    Swal.fire({
      html: `
			Thank you for your submission. Before we proceed, we would like to confirm that all the details provided in your abstract are accurate and complete.<br><br>
			Please note that once the abstract is submitted, no further modifications will be allowed. Kindly review all the content thoroughly.
		`,
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(async result => {
      if (result.isConfirmed) {
        const file = await getPDF(id);
        await handleUploadFile({
          file,
          id,
          type: 'Abstract',
        });
        postData({
          body: {
            id,
            status: 'Submitted',
          },
        });
      }
    });
  };

  const handleUploadFile = async ({ file, id, type }) => {
    const formData = new FormData();
    formData.append('postId', id);
    formData.append('type', type);
    formData.append('file', file);

    try {
      const response = await fetch(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok)
        throw new Error('File upload failed');

      const data = await response.json();
      return data.fileId; // 確保後端有回傳 `fileId`
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeStatus = async ({ id, status }) => {
    if (status !== 'Submitted') {
      handleDeleteSubmission({ id, status });
    } else {
      handleSubmit({ id });
    }
  };

  const handleIconHover = ({ id, type, isHover }) => {
    const newItems = items?.map(element => {
      if (element.id === id) {
        if (type !== 'preview') {
          return {
            ...element,
            icons: {
              ...element.icons,
              [type]: `${process.env.PUBLIC_URL}/images/submission-icon-${type}${isHover ? '-active' : ''}.svg`,
            },
          };
        } else {
          return {
            ...element,
            icons: {
              ...element.icons,
              previewColor: isHover ? '#4D0002' : '#B50004',
            },
          };
        }
      } else return element;
    });
    setItems(newItems);
  };

  useEffect(() => {
    if (success) {
      handleReset();
      fetchData();
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        text: error,
        showClass: { popup: '' },
        hideClass: { popup: '' },
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
          cancelButton: 'swal-btn swal-cancel',
        },
      });
      handleReset();
    }
  }, [error]);

  useEffect(() => {
    if (!isLogin) {
      nav('/sign-in');
    }
  }, [isLogin]);

  return (
    <>
      <div className="container">
        <div className="page-title fz-30">
          Abstract Submission
        </div>
        <div className="form-container">
          <div className="fz-20 fw-800 form-title">
            Submission List
          </div>
          <table className="submission-list">
            <NavLink
              to="/submission/create"
              className="submission-new-btn"
            >
              New Submission
            </NavLink>
            <thead>
              <tr>
                <th></th>
                <th>Ref. No.</th>
                <th>Abstract Title</th>
                <th>Author</th>
                <th>Last Edited</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((item, idx) => (
                <tr key={item.id}>
                  <td>
                    <IoEye
                      className="preview-btn"
                      size={22}
                      color={item.icons.previewColor}
                      cursor={'pointer'}
                      onClick={() =>
                        window.open(
                          `/submission/preview/${item.id}`,
                          '_blank',
                          'rel=noopener noreferrer'
                        )
                      }
                      onMouseOver={() =>
                        handleIconHover({
                          id: item.id,
                          type: 'preview',
                          isHover: true,
                        })
                      }
                      onMouseOut={() =>
                        handleIconHover({
                          id: item.id,
                          type: 'preview',
                          isHover: false,
                        })
                      }
                    />
                  </td>
                  <td>
                    <div className="no-wrap">{item.id}</div>
                  </td>
                  <td>
                    <span className="ellipsis">
                      {item.abstract_title}
                    </span>
                  </td>
                  <td>
                    <span className="ellipsis">
                      {item.authorList &&
                        JSON.parse(item.authorList).map(
                          (author, idx) =>
                            author.name +
                            (idx <
                            item.authorList.length - 1
                              ? ', '
                              : '')
                        )}
                    </span>
                  </td>

                  <td>
                    {moment(item.updated_at).format(
                      'HH:mm:ss'
                    )}
                    <br />
                    {moment(item.updated_at).format(
                      'MMM. DD, yyyy'
                    )}
                  </td>
                  <td data-status={item.status}>
                    {item.status}
                  </td>
                  <td>
                    <div className="edit-group">
                      {item.status !== 'Submitted' && (
                        <>
                          <button
                            className="submission-btn fw-800"
                            onClick={() =>
                              handleChangeStatus({
                                id: item.id,
                                status: 'Submitted',
                              })
                            }
                          >
                            Submit
                          </button>
                          <NavLink
                            to={`/submission/${item.id}`}
                            onMouseOver={() =>
                              handleIconHover({
                                isHover: true,
                                id: item.id,
                                type: 'edit',
                              })
                            }
                            onMouseOut={() =>
                              handleIconHover({
                                isHover: false,
                                id: item.id,
                                type: 'edit',
                              })
                            }
                          >
                            <img
                              src={item.icons.edit}
                              alt="submission-icon-edit"
                            />
                          </NavLink>
                        </>
                      )}
                      <div
                        onClick={() =>
                          handleChangeStatus({
                            id: item.id,
                            status: 'Canceled',
                          })
                        }
                        onMouseOver={() =>
                          handleIconHover({
                            isHover: true,
                            id: item.id,
                            type: 'delete',
                          })
                        }
                        onMouseOut={() =>
                          handleIconHover({
                            isHover: false,
                            id: item.id,
                            type: 'delete',
                          })
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={item.icons.delete}
                          alt="submission-icon-delete"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {!loading && items.length === 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="not-found text-center"
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SubmissionList;
