const ErrorMessage = ({ error, touched, id }) => {
  if (!error || !touched) return null;
  if (
    id === 'retypePassword' &&
    error === 'Passwords must match'
  ) {
    return null;
  }

  return (
    <span className="error-msg fz-12">*required field</span>
  );
};

export default ErrorMessage;
