import router from './contact';
import Layout from './components/Layout';
import { RouterProvider } from 'react-router-dom';

import './assets/scss/form.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-quill/dist/quill.snow.css';
import { AuthContextProvider } from './context/AuthContext';

function App() {
  return (
    // <RequestProvider>
    <AuthContextProvider>
      <RouterProvider router={router}>
        <Layout />
      </RouterProvider>
    </AuthContextProvider>
    // </RequestProvider>
  );
}

export default App;
