import './card.scss';
import '../assets/scss/form.scss';

const Card = ({ children, className = '' }) => {
  // console.log(props);
  return (
    <div className={`card ${className}`}>{children}</div>
  );
};

export default Card;
