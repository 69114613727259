import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const AutoRedirect = ({
  children,
  timeout,
  redirectUrl = '/',
}) => {
  const [time, setTime] = useState(timeout);
  const nav = useNavigate();
  const { isLogin } = useContext(AuthContext);

  useEffect(() => {
    const timer = setInterval(() => {
      if (+time <= 0) {
        clearInterval(timer);
        if (isLogin && redirectUrl === '/sign-in') {
          nav('/');
        } else {
          nav(redirectUrl);
        }
      } else {
        setTime(prev => prev - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [time]);

  return (
    <>
      {children}
      <p className="fz-14 text-center">
        Page Redirection Waiting Time:{time}
      </p>
    </>
  );
};

export default AutoRedirect;
