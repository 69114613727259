import useFetch from '../../hooks/useFetch';
import './header.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DesktopMenu, MobileMenu } from './Menu';

const Header = () => {
  const [styles, setStyles] = useState({});
  const updateStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1480 && screenWidth <= 1920) {
      setStyles({
        marginLeft: `${405 - (1920 - screenWidth) * 0.5}px`,
      });
    } else if (screenWidth > 1920 && screenWidth <= 2240) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.2}px`,
      });
    } else if (screenWidth > 2240) {
      setStyles({
        marginLeft: `${420 - (1920 - screenWidth) * 0.2}px`,
      });
    }
  };

  useEffect(() => {
    updateStyles();
    window.addEventListener('resize', updateStyles);
    return () =>
      window.removeEventListener('resize', updateStyles);
  }, []);

  const { data: items, loading } = useFetch('/menu', {
    method: 'GET',
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] =
    useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const closeMenu = () => {
    if (window.innerWidth >= 1024) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);

    return () =>
      window.removeEventListener('resize', closeMenu);
  }, []);

  if (loading)
    return (
      <nav className="menu-container" style={styles}>
        <div className="menu-header"></div>
      </nav>
    );
  return (
    <>
      <div className="bg-w">
        <nav className="menu-container" style={styles}>
          <div className="menu-header">
            <Link
              to="/"
              onClick={() => {
                if (isMobileMenuOpen)
                  handleToggleMobileMenu();
              }}
            >
              <img
                className="logo"
                src={`${process.env.PUBLIC_URL}/logo192.png`}
                alt="Logo"
              />
            </Link>
            <i
              className="icon"
              onClick={() => handleToggleMobileMenu()}
            />
            <DesktopMenu items={items} />
            {isMobileMenuOpen && (
              <MobileMenu
                items={items}
                onToggle={handleToggleMobileMenu}
              />
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
