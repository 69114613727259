import './keyDate.scss';
import React from 'react';
import moment from 'moment';

const KeyDates = ({ keyDates }) => {
  return (
    <div className="key-date pt-20">
      <p className="fz-30 fw-800 mx-10">KEY DATES</p>
      <div className="arrow-line arrow-line-l-100 arrow-line-w"></div>
      <div className="key-date-container">
        {keyDates?.map((data, idx) => (
          <div className="key-date-list" key={idx}>
            <div className="key-date-title">
              <div className="key-date-date">
                <span>
                  {moment(data.date).format('DD')}.{' '}
                </span>
                <span>
                  {moment(data.date).format('MMM')}, 2025
                </span>
              </div>
              <div className="fz-20 key-date-label">
                {data.label}
              </div>
            </div>
            {data.hyperlink && (
              <div
                className="key-date-link"
                onClick={() =>
                  window.open(data.hyperlink, '_blank')
                }
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyDates;
