import { useParams } from 'react-router-dom';
import Preview from '../../components/submission/Preview';
import useFetch from '../../hooks/useFetch';

const SubmissionPreview = () => {
  const { id } = useParams();
  const { loading, data } = useFetch(`/submission/${id}`);

  if (loading) return;

  return <Preview data={data.data} />;
};

export default SubmissionPreview;
