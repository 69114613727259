import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import '../../assets/scss/form.scss';
import useFetch from '../../hooks/useFetch';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const Join = () => {
  const nav = useNavigate();
  const { error, fetchData, handleReset, data } = useFetch(
    `/is-register`,
    {},
    true
  );

  useEffect(() => {
    if (error) {
      Swal.fire({
        text: error,
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      }).then(() => {
        handleReset();
        nav('/membership?toBottom=1');
      });
    }
  }, [error]);

  useEffect(() => {
    if (data?.success) {
      nav('/join/register');
    }
  }, [data]);

  return (
    <div className="container">
      <div className="page-title fz-30">Registration</div>
      <div className="form-container">
        <div className="fz-20 fw-800 form-title">
          The Congress Organizers welcome you to register
          for the APSAVD 2025 Congress !
        </div>
        <div className="fw-800 mt-20">
          Important Date for the Participant
        </div>
        <Card>
          On-line Registration System Closed{' '}
          <span className="fw-800 color-red">
            23:59, August 15, 2025(GMT+8)
          </span>
        </Card>
        <div className="fw-800 mt-20">
          Summary of the Registration
        </div>
        <Card>
          <div>
            <p className="mx-10">
              • For each{' '}
              <span className="fw-800">
                Accepted Abstract
              </span>
              , the presenting author should be a registered
              participant of APSAVD 2025 Congress.
            </p>
            <p className="mx-10">
              • Registration is not confirmed until the full
              payment is received.
            </p>
            <p className="mx-10">
              • You may{' '}
              <span className="fw-800">download</span> your
              registration receipt and invitation letter{' '}
              <span className="fw-800">online</span> after
              the full payment is received.
            </p>
          </div>
        </Card>
        <div className="mt-30">
          <div className="mb-10">
            <span className="fw-800">
              Any further question regarding submission,
              please contact:
            </span>
          </div>
          APSAVD 2025 Congress Registration & Submission
          Office
          <br />
          Email:{' '}
          <a href="mailto:registration@apsavd2025.tw">
            registration@apsavd2025.tw
          </a>
          <br />
          Website:{' '}
          <a href="https://apsavd2025.tw/">
            https://apsavd2025.tw/
          </a>
        </div>
        <button
          className="primary-btn fw-800 mt-40"
          onClick={() => {
            // nav('/join/register');
            fetchData();
          }}
        >
          Register Now!
        </button>
      </div>
    </div>
  );
};

export default Join;
