import { useContext, useEffect } from 'react';
import {
  NavLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import usePost from '../../../hooks/usePost';
import AutoRedirect from '../../../components/AutoRedirect';
import { AuthContext } from '../../../context/AuthContext';
import './verifyEmailToken.scss';
import { SiV } from 'react-icons/si';

const VerifyEmailToken = () => {
  const { token } = useParams();
  const { postData, error, success, loading } = usePost(
    '/verify-email-token'
  );
  const { isLogin } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      postData({
        body: {
          token,
        },
      });
    }
  }, [token]);

  return (
    <div className="container">
      {loading ? (
        <FadeLoader
          color="hsl(359, 100%, 35%)"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <div className="verify-container">
          <AutoRedirect timeout={5} redirectUrl="/sign-in">
            {success && (
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/verify-icon-success.svg`}
                  alt="verify-icon-success"
                />
                <p className="fz-20 color-green text-center fw-800 my-40">
                  Account Verification Successful
                </p>
              </div>
            )}
            {error && (
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/verify-icon-error.svg`}
                  alt="verify-icon-error"
                />
                <p className="fz-20 color-red text-center fw-800 my-40">
                  Account Verification Failed. Please
                  Register a New Account.
                </p>
              </div>
            )}
          </AutoRedirect>
          <NavLink
            className="hover color-blue mt-40"
            to={`${isLogin ? '/' : '/sign-in'}`}
          >
            ⭠ Back to Log in
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default VerifyEmailToken;
