import { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext({
  login: async ({ username = '', password = '' }) => {},
  logout: () => {},
  checkTokenValidity: () => {},
  token: '',
  user: null,
  isLogin: false,
  onUpdateUser: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(
    localStorage.getItem('apsavd_user_token') || null
  );
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem('apsavd_user_token') ? true : false
  );

  const login = async ({ username, password }) => {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}/jwt-auth/v1/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      }
    );

    try {
      if (response.ok) {
        const result = await response.json();
        localStorage.setItem(
          'apsavd_user_token',
          result.token
        );

        if (
          localStorage.getItem('apsavd_username_status') ===
          'remembered'
        ) {
          localStorage.setItem('apsavd_username', username);
        }
        setToken(result.token);

        getUserData(result.token);

        setIsLogin(true);

        return 'OK';
      } else {
        // Swal.fire({
        //   text:
        //     +response.status === 500
        //       ? 'Account not verified yet.'
        //       : 'Incorrect account or password.',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'swal-btn swal-confirm',
        //   },
        //   showClass: { popup: '' },
        //   hideClass: { popup: '' },
        // });
        return response.status === 500
          ? 'Account not verified yet.'
          : 'Incorrect account or password.';
      }
    } catch (error) {
      // Swal.fire({
      //   text: error,
      //   showClass: { popup: '' },
      //   hideClass: { popup: '' },
      // });
      return error;
    }
  };

  const logout = () => {
    setIsLogin(false);
    setUser(null);
    setToken(null);
    localStorage.removeItem('apsavd_user_token');
  };

  const getUserData = async userToken => {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/user`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response.json();

    if (!response.ok) {
      logout();
    } else {
      setIsLogin(true);
      setUser(data);
    }
  };

  const checkTokenValidity = async () => {
    if (token) {
      try {
        const response = await fetch(
          // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/check-token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          logout();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      checkTokenValidity(token);
    }
  }, []);

  const updateUser = () => {
    if (token) getUserData(token);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        token,
        user,
        logout,
        isLogin,
        checkTokenValidity,
        onUpdateUser: updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
