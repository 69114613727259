import { useContext, useEffect } from 'react';
import PersonalInformation from '../../components/auth/PersonalInformation';
import { AuthContext } from '../../context/AuthContext';
import { useFormik } from 'formik';
import Collapse from '../../components/Collapse';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useFetch from '../../hooks/useFetch';
import Input from '../../components/Input';
import usePost from '../../hooks/usePost';
import './Membership.scss';

const Membership = () => {
  const nav = useNavigate();
  const location = useLocation();
  const handleCancel = () => {
    Swal.fire({
      text: 'Would you kindly confirm that you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        nav('/');
      }
    });
  };

  const { user, isLogin, onUpdateUser } =
    useContext(AuthContext);
  const {
    postData: updateUser,
    data,
    success: updateSuccess,
    error: updateError,
    loading,
  } = usePost('/update-user');
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...user,
      country: {
        label: user?.country,
      },
      title: {
        label: user?.title,
        value: user?.title,
      },
      countryCode: {
        label: user?.countryCode,
        value: user?.countryCode,
      },
    },
    onSubmit: () => {
      const body = {
        ...values,
        title: values.title.label,
        countryCode: values.countryCode.label,
      };

      updateUser({
        body,
      });
    },
  });
  const { data: registration } = useFetch(
    `/registration/${user?.registrationId}`
  );

  useEffect(() => {
    if (!isLogin) {
      nav('/');
    }
  }, [isLogin]);

  useEffect(() => {
    if (location.search && registration?.success) {
      const param = new URLSearchParams(location.search);

      if (param.get('toBottom')) {
        const target = document.getElementById(
          'registration-info'
        );
        target?.scrollIntoView({
          behavior: 'smooth',
          // block: 'center',
        });
      }
    }
  }, [location.search, registration?.success]);

  const {
    postData,
    data: response,
    success,
    error,
  } = usePost('/checkout');

  const handleCheckout = () => {
    const id = registration?.data?.id;
    // const body = {
    //   id: registration?.data?.id,
    // };
    // postData({ body });
    nav(`/payment/${id}`);
  };

  useEffect(() => {
    if (!loading && updateSuccess) {
      onUpdateUser();
      Swal.fire({
        text: 'Your member information has been updated',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
        },
      });
    }
  }, [updateSuccess, loading]);

  // useEffect(() => {
  //   if (success && response) {
  //     const form = document.createElement('form');
  //     form.method = 'POST';
  //     form.action =
  //       'https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/';

  //     const params = response.data.split('?')[1].split('&');
  //     form.target = '_blank';
  //     params.forEach(param => {
  //       const [key, value] = param.split('=');
  //       const input = document.createElement('input');
  //       input.type = 'hidden';
  //       input.name = key;
  //       input.value = value;
  //       form.appendChild(input);
  //     });

  //     document.body.appendChild(form);
  //     form.submit();
  //     document.body.removeChild(form);
  //   }
  // }, [success, response]);

  return (
    <div className="container">
      <div className="page-title fz-30">
        Membership Information
      </div>
      <div className="form-container">
        <Collapse
          title={'Personal Information'}
          isDefaultOpen={true}
          className={'mb-40'}
        >
          <PersonalInformation
            values={values}
            isProfile={true}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
        </Collapse>
        {user?.registrationId && registration?.success && (
          <>
            <Collapse
              title={'Registration Information'}
              className={'mb-40'}
              isDefaultOpen={true}
            >
              <table
                className="registration-form-style"
                id="registration-info"
              >
                <thead>
                  <tr>
                    <th>Categories</th>
                    <th>Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {
                        registration?.data?.registrationType
                          ?.label
                      }
                    </td>
                    <td>{registration?.data?.amount}</td>
                  </tr>
                  {registration?.data?.paymentStatus ===
                    'Payment Completed' && (
                    <tr>
                      <td colSpan={2}>
                        <div className="info-container">
                          <p className="fw-800">
                            Registration No.
                          </p>
                          <div className="completed-icon">
                            <Input
                              disabled={true}
                              value={
                                registration?.data
                                  ?.registrationNo
                              }
                            />
                            <div className="info-icon">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/verify-icon-success.svg`}
                                alt="verify-icon-success"
                              />
                              <p className="fz-14 color-green">
                                Registration completed
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Collapse>
            <Collapse
              title={'Dietary Request'}
              className={'mb-40'}
              isDefaultOpen={true}
            >
              <div className="p-20">
                {registration?.data?.dietaryRequest}
              </div>
            </Collapse>
            {+registration?.data?.amount > 0 && (
              <Collapse
                className={'mb-40'}
                title="Payment Method"
                isDefaultOpen={true}
              >
                <div className="p-20">
                  <div className="fw-800">
                    Registration Information
                  </div>
                  <div className="mt-20">
                    <div className="info-container">
                      <p>Receipt Title</p>
                      <Input
                        disabled={true}
                        value={
                          registration?.data?.receiptTitle
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <div className="info-container">
                      <p>統一編號</p>
                      <Input
                        disabled={true}
                        value={registration?.data?.taxId}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="info-container">
                      <p>Total Amount</p>
                      <Input
                        disabled={true}
                        value={registration?.data?.amount}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="info-container">
                      <p>Payment Status</p>
                      <Input
                        disabled={true}
                        style={{
                          color:
                            registration?.data
                              ?.paymentStatus ===
                            'Payment Completed'
                              ? '#5cd131'
                              : '#ff4a4a',
                          fontWeight: 'bold',
                        }}
                        value={
                          registration?.data
                            ?.paymentStatus ||
                          'Payment Needed'
                        }
                      />
                      {registration?.data?.paymentStatus !==
                        'Payment Completed' && (
                        <button
                          className="pay-btn"
                          onClick={() => handleCheckout()}
                        >
                          Pay Online
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Collapse>
            )}
          </>
        )}

        <div className="button-group">
          <button
            className="secondary-btn fw-800 mt-40"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn fw-800 mt-40"
            onClick={() => handleSubmit()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Membership;
