import Input from '../Input';
import '../../assets/scss/form.scss';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePost from '../../hooks/usePost';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import PersonalInformation from './PersonalInformation';
import Swal from 'sweetalert2';

const SignUpForm = () => {
  const fieldsMapping = {
    firstName: 'first_name',
    middleName: 'middle_name',
    lastName: 'last_name',
    chineseName: 'chinese_name',
    institutionChineseName: 'institution_chinese_name',
    contactTel: 'contact_tel',
    idNumber: 'id_number',
    countryCode: 'country_code',
  };
  const nav = useNavigate();

  const handleCancel = () => {
    Swal.fire({
      text: 'Would you kindly confirm that you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        nav('/');
      }
    });
  };

  const { postData, success, error, loading, handleReset } =
    usePost('/sign-up');

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      country: null,
      city: '',
      title: null,
      gender: null,
      firstName: '',
      middleName: '',
      lastName: '',
      chineseName: '',
      institution: '',
      institutionChineseName: '',
      contactTel: '',
      email: '',
      password: '',
      retypePassword: '',
      idNumber: '',
      countryCode: '',
    },
    validationSchema: yup.object().shape({
      country: yup.object().shape({
        value: yup.string().required(),
      }),
      city: yup.string().required(),
      title: yup.object().shape({
        value: yup.string().required(),
      }),
      gender: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      institution: yup.string().required(),
      contactTel: yup.string().required(),
      email: yup.string().email().required(),
      password: yup
        .string()
        .required()
        .matches(
          /^.{6,12}$/,
          'Your password is incorrect.'
        ),
      retypePassword: yup
        .string()
        .required('Please retype your password')
        .oneOf(
          [yup.ref('password')],
          'Your password is incorrect.'
        )
        .matches(
          /^.{6,12}$/,
          'Your password is incorrect.'
        ),
      idNumber: yup.string().when('country', {
        is: val => val?.label === 'Taiwan',
        then: () =>
          yup
            .string()
            .required()
            .matches(/^[A-Z][12]\d{8}$/),
        otherwise: () => yup.string().nullable(),
      }),
      countryCode: yup.object().shape({
        label: yup.string().required(),
      }),
    }),
    onSubmit: rest => {
      const body = {};

      Object.entries(rest).forEach(([key, val]) => {
        if (!val) {
          return;
        }
        if (fieldsMapping[key]) {
          body[fieldsMapping[key]] =
            typeof val === 'object' ? val.value : val;
        } else {
          body[key] =
            typeof val === 'object' ? val.value : val;
        }
      });

      postData({ body });
    },
  });

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (success) {
      Swal.fire({
        width: isMobile ? '90%' : '50%',
        showConfirmButton: true,
        confirmButtonText: 'Back to Log in',
        confirmButtonColor: 'var(--primary-red)',
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
        },
        html: `<div class="fz-20 fw-800 form-title">
                 <b> Activate Your Account </b>
               </div>
               <div class="text-left">
                 <p class="fw-500 mx-10">
                   • A verification email has been sent to your address. Please activate your account within 30 minutes, as the code will expire and require resending.
                 </p>
                 <p class="mt-20 fw-500 mx-10">
                   • Please note that future APSAVD 2025 Congress notifications will be sent to your email address.
                 </p>
               </div>
               <div class="text-left fz-14">
                 For inquiries, please contact APSAVD 2025 Congress Registration & Submission Office
               </div>
               <div class="text-left mt-20 fz-14">
                 APSAVD 2025 Congress Registration & Submission Office
               </div>
               <div class="text-left fz-14">
                 Email: <a href="mailto:registration@apsavd2025.tw">registration@apsavd2025.tw</a>
               </div>
               <div class="text-left fz-14">
                 Website: <a href="https://apsavd2025.tw/">https://apsavd2025.tw/</a>
               </div>`,
        showClass: { popup: '' },
        hideClass: { popup: '' },
      }).then(() => {
        nav('/sign-in');
      });
    }
  }, [success, nav]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        text: error,
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      }).then(() => {
        handleReset();
      });
    }
  }, [error, handleReset]);

  return (
    <div className="form-container">
      <div className="page-title fz-30">
        Personal Information
      </div>
      <p className="fz-14 text-center mb-20">
        * = required field
      </p>
      <PersonalInformation
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />
      <div className="form-group">
        <div className="form-item w-100">
          <label>
            Password<span className="require">*</span>
          </label>
          <Input
            id="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            invalid={errors.password && touched.password}
          />
          <label className="fz-12 color-gray">
            Please enter a combination of 6 ~ 12 numbers or
            characters.
          </label>
          {errors.password && touched.password && (
            <p className="fz-12 color-red">
              Your password is incorrect.
            </p>
          )}
          <ErrorMessage
            error={errors.password}
            touched={touched.password}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-item w-100">
          <label>
            Retype Password
            <span className="require">*</span>
          </label>
          <Input
            id="retypePassword"
            type="password"
            value={values.retypePassword}
            onChange={handleChange}
            invalid={
              errors.retypePassword &&
              touched.retypePassword
            }
          />
          <label className="fz-12 color-gray">
            Please enter a combination of 6 ~ 12 numbers or
            characters.
          </label>
          {errors.retypePassword &&
            touched.retypePassword &&
            errors.retypePassword ===
              'Your password is incorrect.' && (
              <p className="fz-12 color-red">
                Your password is incorrect.
              </p>
            )}
          <ErrorMessage
            id="retypePassword"
            error={errors.retypePassword}
            touched={touched.retypePassword}
          />
        </div>
      </div>
      <div className="button-group">
        <button
          className="secondary-btn fw-800 mt-40"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="primary-btn fw-800 mt-40"
          disabled={loading}
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default SignUpForm;
