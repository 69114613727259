import {
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import { BiChevronDown } from 'react-icons/bi';
import { TfiClose } from 'react-icons/tfi';

const memberItems = {
  ID: 'menu-item-member',
  title: 'MEMBER',
  children: [
    {
      ID: 'member-info',
      title: 'MEMBERSHIP INFORMATION',
      url: '/membership',
    },
    {
      ID: 'member-logout',
      title: 'LOG OUT',
      url: '',
    },
  ],
};

const useHandleLogout = () => {
  const { logout } = useContext(AuthContext);
  const nav = useNavigate();
  const handleLogout = () => {
    logout();
    Swal.fire({
      text: 'You have successfully logged out.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
      },
    }).then(() => {
      nav('/');
    });
  };

  return handleLogout;
};

const DesktopMember = () => {
  // const memberMenu = MemberMenuData();
  const { isLogin, logout } = useContext(AuthContext);
  const nav = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === '/membership';
  const handleLogout = useHandleLogout();

  return (
    <>
      {isLogin ? (
        <div
          className={`menu-item menu-item-member ${
            isActive ? 'active' : ''
          }`}
          onMouseEnter={e => {
            const img =
              e.currentTarget.querySelector('img');
            if (img) {
              img.src = `${process.env.PUBLIC_URL}/images/member-hover.png`;
            }
          }}
          onMouseLeave={e => {
            const img =
              e.currentTarget.querySelector('img');
            if (img) {
              img.src = `${process.env.PUBLIC_URL}/images/member.png`;
            }
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/member.png`}
            alt="Member"
            className="menu-icon"
          />
          <div className="sub-menu">
            {memberItems.children.map(child => (
              <div
                className="item"
                key={child.ID}
                onClick={() => {
                  if (child.ID === 'member-logout') {
                    handleLogout();
                  } else {
                    nav(child.url);
                  }
                }}
              >
                {child.title}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NavLink to={'/sign-in'}>
          <div className="menu-item-member-login">
            LOG IN
          </div>
        </NavLink>
      )}
    </>
  );
};

export const DesktopMenu = ({ items }) => {
  const nav = useNavigate();
  if (!items) return;
  return (
    <>
      {items?.map(item => (
        <div
          className={`menu-item ${
            item.children.length === 0
              ? item.url === location.pathname && 'active'
              : item.children.find(
                  child => child.url === location.pathname
                ) && 'active'
          }`}
          key={item.ID}
          onClick={() =>
            item.children.length === 0 && nav(item.url)
          }
        >
          {item.title}
          {item.children.length > 0 && (
            <Fragment>
              <BiChevronDown className="arrow-icon" />
              <div className="sub-menu">
                {item.children.map(child => (
                  <div
                    className="item"
                    key={child.ID}
                    onClick={() => {
                      nav(child.url);
                    }}
                  >
                    {child.title}
                  </div>
                ))}
              </div>
            </Fragment>
          )}
        </div>
      ))}
      <DesktopMember />
    </>
  );
};

export const MobileMenu = ({ items, onToggle }) => {
  const nav = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  // const memberMenu = useMemo(() => MemberMenuData(), []);
  const { isLogin, logout } = useContext(AuthContext);
  const handleLogout = useHandleLogout();

  useEffect(() => {
    const initializeMenu = () => {
      let newItems = items?.map(item => ({
        ...item,
        isExtened: item.children.some(
          child =>
            child.url === location.pathname &&
            location.pathname !== '/'
        ),
      }));

      if (isLogin) {
        newItems = [
          ...newItems,
          ...memberItems.children.map(child => ({
            ...child,
            isExtened: false,
          })),
        ];
      } else {
        newItems.push({
          ID: 'login',
          title: 'LOG IN',
          url: '/sign-in',
          children: [],
        });
      }

      setMenuItems(newItems);
    };
    initializeMenu();
  }, [items, location.pathname, isLogin]);

  const toggleSubMenu = itemId => {
    setMenuItems(prev =>
      prev.map(item => ({
        ...item,
        isExtened:
          item.ID === itemId ? !item.isExtened : false,
      }))
    );
  };

  const handleToggleMenu = () => {
    setMenuItems(prev =>
      prev.map(item => ({
        ...item,
        isExtened: false,
      }))
    );
    onToggle();
  };

  return (
    <div className="menu-mask" onClick={handleToggleMenu}>
      <div
        className="menu-item-mobile"
        onClick={e => e.stopPropagation()}
      >
        <div className="menu-item-mobile-header">
          <NavLink to="/" onClick={handleToggleMenu}>
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/logo192.png`}
              alt="Logo"
            />
          </NavLink>
          <div
            className="close-btn"
            onClick={handleToggleMenu}
          >
            <TfiClose />
          </div>
        </div>

        {menuItems.map(item => (
          <div
            key={item.ID || item.title}
            className={`item-mobile ${
              item.title === 'MEMBERSHIP INFORMATION' ||
              item.title === 'LOG OUT' ||
              item.title === 'LOG IN'
                ? 'custom-item'
                : ''
            }`}
          >
            <div
              className={`main-item ${
                item.isExtened ? 'extended' : ''
              }`}
              onClick={() => {
                if (item.ID === 'member-logout') {
                  handleLogout();
                  setTimeout(() => {
                    handleToggleMenu();
                  }, 500);
                } else if (item.children?.length) {
                  toggleSubMenu(item.ID);
                } else {
                  nav(item.url);
                  handleToggleMenu();
                }
              }}
            >
              {item.title}
              {item.children?.length > 0 && (
                <BiChevronDown
                  className={`arrow-icon ${
                    item.isExtened ? 'rotated' : ''
                  }`}
                />
              )}
            </div>

            {item.isExtened &&
              item.children?.map((child, i) => (
                <div
                  key={i}
                  className={`sub-item ${
                    child.url === location.pathname
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    nav(child.url);
                    handleToggleMenu();
                    setMenuItems(prev =>
                      prev.map(menuItem => ({
                        ...menuItem,
                        isExtened: false,
                      }))
                    );
                  }}
                >
                  {child.title}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
