import { useContext, useEffect, useState } from 'react';
import Input from '../../components/Input';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import usePost from '../../hooks/usePost';
import Swal from 'sweetalert2';
import '../join/payment.scss';

const Payment = () => {
  const { id } = useParams();
  const { user, checkTokenValidity } =
    useContext(AuthContext);
  const { data, loading } = useFetch(`/registration/${id}`);
  const nav = useNavigate();
  const [values, setValues] = useState({
    receiptTitle: '',
    taxId: '',
  });
  const {
    postData,
    loading: postLoading,
    success,
    error,
    data: response,
    handleReset,
  } = usePost('/checkout');

  useEffect(() => {
    checkTokenValidity();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      !data.success && nav('/');
    }
  }, [data, loading]);

  useEffect(() => {
    if (data?.data) {
      setValues({
        receiptTitle: data.data.receiptTitle,
        taxId: data.data.taxId,
      });
    }
  }, [data?.data]);

  const handleSubmit = async () => {
    const body = {
      id,
      receiptTitle: values.receiptTitle,
      taxId: values.taxId,
    };
    postData({
      body,
    });
  };

  useEffect(() => {
    if (success && response) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        response.data,
        'text/html'
      );
      const form = doc.querySelector('form');

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  }, [success, response]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        text: error,
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      }).then(() => {
        handleReset();
      });
    }
  }, [error]);

  return (
    <div className="container">
      <div className="page-title fz-30">Registration</div>
      <div className="form-container">
        <div className="fz-20 fw-800 form-title">
          Payment Method
        </div>
        <div className="mt-20 fw-800">
          Receipt Information
        </div>
        <div className="payment-container">
          <label>Receipt Title</label>
          <Input
            value={values.receiptTitle}
            onChange={e =>
              setValues({
                ...values,
                receiptTitle: e.target.value,
              })
            }
          />
        </div>
        {user?.country === 'Taiwan' && (
          <div className="payment-container">
            <label>統一編號</label>
            <Input
              value={values.taxId}
              onChange={e =>
                setValues({
                  ...values,
                  taxId: e.target.value,
                })
              }
            />
          </div>
        )}
        <div className="payment-container">
          <label>Total Amount</label>
          <Input
            disabled={true}
            value={data?.data?.amount}
          />
        </div>
        <div className="mt-40 fw-800">Payment</div>
        <div>Credit Card</div>
        <div>
          <p className="mx-10">
            • Only Visa, Master and JCB Card are accepted.
          </p>
          <p className="mx-10">
            • You may link to the online credit card payment
            system at the next step when you have the credit
            card ready in hand.
          </p>
        </div>
        <div>ATM</div>
        <div>
          <p className="mx-10">
            • Payment can be made via online ATM.
          </p>
        </div>
        <button
          className="primary-btn fw-800 mt-40"
          disabled={postLoading || success}
          onClick={() => handleSubmit()}
        >
          Pay Online
        </button>
      </div>
    </div>
  );
};

export default Payment;
