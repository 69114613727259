import ReactQuill from 'react-quill';
const AbstractContentEditor = ({
  value,
  handleChange,
  error,
  touched,
  onBlur,
}) => (
  <div
    className={`quill-editor ${error && touched ? 'error' : ''}`}
  >
    <ReactQuill
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      theme="snow"
      modules={{
        toolbar: [
          ['bold', 'italic', 'underline', { color: [] }],
          [{ align: [] }],
          [{ list: 'bullet' }, { list: 'ordered' }],
        ],
      }}
    />
  </div>
);

export default AbstractContentEditor;
