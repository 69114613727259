import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../components/Input';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import usePost from '../../hooks/usePost';
import AutoRedirect from '../../components/AutoRedirect';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../../components/ErrorMessage';

const ResetPassword = () => {
  const { token } = useParams();
  const { isLogin } = useContext(AuthContext);

  const { loading, error, success, postData } = usePost(
    '/reset-password'
  );
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      password: '',
      retypePassword: '',
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required()
        .matches(/^.{6,12}$/),
      retypePassword: yup
        .string()
        .required()
        .oneOf(
          [yup.ref('password')],
          'Passwords must match'
        )
        .matches(/^.{6,12}$/),
    }),
    onSubmit: () => {
      const body = {
        token,
        password: values.password,
      };

      postData({ body });
    },
  });

  return (
    <div className="container">
      {!error && !success ? (
        <div className="form-container">
          <div className="w-70 margin-auto">
            <div className="page-title fz-30">
              Reset Password
            </div>
            <div className="form-item margin-auto w-30 mt-20">
              <label>
                Password<span className="require">*</span>
              </label>
              <Input
                type="password"
                id="password"
                onChange={handleChange}
                value={values.password}
                invalid={
                  errors.password && touched.password
                }
              />
              <label className="fz-12 color-gray">
                Please enter a combination of 6 ~ 12 numbers
                or characters.
              </label>
              <ErrorMessage
                error={errors.password}
                touched={touched.password}
              />
            </div>
            <div className="form-item margin-auto w-30 mt-40">
              <label>
                Retyped Password
                <span className="require">*</span>
              </label>
              <Input
                type="password"
                id="retypePassword"
                onChange={handleChange}
                value={values.retypePassword}
                invalid={
                  errors.retypePassword &&
                  touched.retypePassword
                }
              />
              {errors.retypePassword &&
                touched.retypePassword &&
                errors.retypePassword ===
                  'Passwords must match' && (
                  <label className="fz-12 color-red">
                    Your account or password is incorrect.
                  </label>
                )}
              <ErrorMessage
                id="retypePassword"
                error={errors.retypePassword}
                touched={touched.retypePassword}
              />
            </div>
            <div className="form-item margin-auto w-30">
              <button
                type="submit"
                className="primary-btn fw-800 mt-40"
                disabled={loading}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="verify-container">
          <AutoRedirect timeout={5} redirectUrl="/sign-in">
            {success && (
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/verify-icon-success.svg`}
                  alt="verify-icon-success"
                />
                <p className="fz-20 color-green text-center fw-800 my-40">
                  Modification successful.
                </p>
              </div>
            )}
            {error && (
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/verify-icon-error.svg`}
                  alt="verify-icon-error"
                />
                <p className="fz-20 color-red text-center fw-800 my-40">
                  Password modification failed. Please
                  resubmit.
                </p>
              </div>
            )}
          </AutoRedirect>
          <NavLink
            className="hover color-blue mt-40"
            to={`${isLogin ? '/' : '/sign-in'}`}
          >
            ⭠ Back to Log in
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
