import SignUpForm from '../../../components/auth/SignUpForm';

const SignUp = () => {
  return (
    <>
      <div className="container">
        <SignUpForm />
      </div>
    </>
  );
};

export default SignUp;
