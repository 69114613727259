import { useContext, useEffect, useState } from 'react';
import SubmissionForm from '../../components/submission/SubmissionForm';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePost from '../../hooks/usePost';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const SubmissionCreate = () => {
  const nav = useNavigate();
  const { token, isLogin } = useContext(AuthContext);
  const {
    loading,
    success,
    postData: createSubmission,
    data: submission,
    handleReset,
  } = usePost('/submission');

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      abstractTitle: '',
      category: null,
      presentationType: null,
      institute: '',
      instituteList: [],
      authorList: [
        {
          name: '',
          email: '',
          instituteList: [],
        },
      ],
      content: {
        background: '',
        method: '',
        results: '',
        conclusion: '',
      },
      yia: '',
      cvFile: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      abstractTitle: yup.string().required(),
      category: yup.object().shape({
        label: yup.string().required(),
      }),
      presentationType: yup.string().required(),
      authorList: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
          instituteList: yup.array().min(1, ''),
        })
      ),
      content: yup.object().shape({
        background: yup.string().required(),
        method: yup.string().required(),
        results: yup.string().required(),
        conclusion: yup.string().required(),
      }),
      // contributors: yup.array().min(1).required(),
      // yia: yup.string().required(),
      cvFile: yup
        .mixed()
        .nullable()
        .when('yia', {
          is: val => val === 'Attend',
          then: () => yup.mixed().required(),
          otherwise: () => yup.mixed().nullable(),
        }),
    }),
    onSubmit: () => {
      Swal.fire({
        showCancelButton: true,
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
          confirmButton: 'swal-btn swal-confirm',
          cancelButton: 'swal-btn swal-cancel',
        },
        showClass: { popup: '' },
        hideClass: { popup: '' },
        html: `
									<div class="fz-20 fw-800 form-title">
								 <b> Review & Submit </b>
							 </div>
					<div class="text-left mt-20 fw-500">
				 Please review the abstract and authors’ information” to revise the submission or “Submit the Abstract” to complete the submission process. You may edit your submitted abstract before the deadline <span class='color-red fw-800'>23:59, June 30, 2025 (GMT+8)</span>.
				</div>`,
      }).then(result => {
        if (result.isConfirmed) {
          handleCreateSubmission();
        }
      });
    },
  });

  const handleCreateSubmission = () => {
    const body = {
      abstractTitle: values.abstractTitle,
      category: values.category.id,
      content: values.content,
      yia: values.yia || 'Not Attend',
      presentationType: values.presentationType,
      // WP ACF Bug
      instituteList: JSON.stringify(values.instituteList),
      authorList: JSON.stringify(
        values.authorList.filter(author => !!author.name)
      ),
    };
    createSubmission({ body });
    // console.log(body);
  };

  const handleUploadCVFile = async id => {
    const formData = new FormData();
    formData.append('postId', id);
    formData.append('type', 'CV');
    const filename = `CV_File.${values.cvFile.name.split('.').pop().toLowerCase()}`;
    const file = new File(
      [values.cvFile],
      filename.replace(/\s+/g, '_'),
      {
        type: values.cvFile.type,
      }
    );
    formData.append('file', file);

    return fetch(
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    ).then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return { sucess: false };
      }
    });
  };

  useEffect(() => {
    if (submission && success) {
      if (values.cvFile) {
        Promise.all([handleUploadCVFile(submission.id)])
          .then(results => {
            if (
              results.every(
                result => result.success === true
              )
            ) {
              nav('/submission');
            } else {
              console.error('Upload file failed');
              handleReset();
            }
          })
          .catch(e => console.error(e));
      } else {
        nav('/submission');
      }
    }
  }, [success, submission]);

  useEffect(() => {
    if (!isLogin) {
      nav('/sign-in');
    }
  }, [isLogin]);

  return (
    <>
      <div className="container">
        <div className="page-title fz-30">
          Abstract Submission
        </div>
        <SubmissionForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default SubmissionCreate;
