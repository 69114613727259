import { useEffect } from 'react';
// import LoginForm from '../../components/auth/SignInForm';
// import { useAuth } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
// import Skeleton from 'react-loading-skeleton';
// import { FadeLoader } from 'react-spinners';
import SignInForm from '../../components/auth/SignInForm';

const SignIn = () => {
  // const { handleValidate, loading } = useAuth();
  const nav = useNavigate();

  return (
    <div className="container">
      <SignInForm />
    </div>
  );
};

export default SignIn;
