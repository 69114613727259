import useFetch from '../hooks/useFetch';

import '../assets/scss/speaker.scss';

const Speaker = () => {
  const { data } = useFetch('/speaker');

  return (
    <>
      {data && (
        <div className="container">
          <div className="page-title fz-30">
            Invited Speakers
          </div>
          <div className="page-content-box">
            <div className="speaker-list">
              {data.data.map(item => (
                <div key={item.id} className="item">
                  <img
                    src={item.avatarUrl}
                    alt={`${item.name}-avatar`}
                    className="avatar"
                  />
                  <b className="fz-18 name">{item.name}</b>
                  <small>{item.city}</small>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Speaker;
