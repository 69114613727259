import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import useFetch from '../../hooks/useFetch';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Result = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { data, fetchData, loading } = useFetch(
    `/registration/${id}`
  );
  const { user } = useContext(AuthContext);
  const isFree = +data?.data?.amount === 0;

  useEffect(() => {
    let timer = null;
    if (
      !loading &&
      !data?.data?.paymentStatus &&
      +data?.data?.amount !== 0
    ) {
      timer = setInterval(() => {
        fetchData();
      }, 8000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [loading, data?.data?.paymentStatus]);

  if (!data) return null;

  return (
    <div className="container">
      <div className="page-title fz-30">Registration</div>
      <div className="form-container">
        {(data?.data?.paymentStatus ===
          'Payment Completed' ||
          +data?.data?.amount === 0) && (
          <div className="fz-20 fw-800 form-title">
            On-line registration is completed!
          </div>
        )}
        {(data?.data?.paymentStatus === 'Payment Needed' ||
          !data?.data?.paymentStatus) &&
          +data?.data?.amount !== 0 && (
            <div className="fz-20 text-center">
              After completing the credit card payment,
              <br />
              please stay on this page until the status
              updates to{' '}
              <span className="color-green">
                &quot;Payment Completed.&quot;
              </span>
            </div>
          )}
        <div className="card-group mt-40">
          <Card className="text-center">
            <div>Registration No.</div>
            <div className="fz-20 fw-800 mt-10">
              {data?.data?.registrationNo}
            </div>
          </Card>
          {!isFree ? (
            <Card className="text-center">
              <div>Payment Status</div>
              <div
                className={`fz-20 fw-800 mt-10 ${
                  data?.data?.paymentStatus ===
                  'Payment Completed'
                    ? 'color-green'
                    : 'color-red'
                }`}
              >
                {data?.data?.paymentStatus ||
                  'Payment Needed'}
              </div>
            </Card>
          ) : (
            <Card className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/verify-icon-success.svg`}
                alt="status icon"
              />
              <div className="fz-20 fw-800 color-green mt-10">
                Registration completed
              </div>
            </Card>
          )}
        </div>
        <div className="mt-20">{`Dear ${user?.title} ${user?.firstName} ${user?.middleName || ''} ${user?.lastName},`}</div>
        <div>
          On behalf of the APSAVD 2025 Congress Organizing
          Committee, we thank you for your participation in
          the congress.
        </div>
        {isFree ? (
          <>
            <div>
              {`Your Registration Number is : ${data?.data?.registrationNo}, and
              registration process has been completed.`}
            </div>
          </>
        ) : (
          <>
            {(data?.data?.paymentStatus ===
              'Payment Needed' ||
              !data?.data?.paymentStatus) && (
              <div>
                {`Your Registration Number is: ${data?.data?.registrationNo}, please complete the registration process with payment in the amount of ${data?.data?.amount} at your earliest convenience.`}
              </div>
            )}
            {data?.data?.paymentStatus ===
              'Payment Failed' && (
              <div>{`Your payment was unsuccessful, please complete the registration process with payment in the amount of ${data?.data?.amount} at your earliest convenience`}</div>
            )}
            {data?.data?.paymentStatus ===
              'Payment Completed' && (
              <>
                <div>{`Your Registration Number is: ${data?.data?.registrationNo}.Your online registration has been completed with full payment.`}</div>
                <div>
                  We look forward to seeing you in Taipei,
                  Taiwan!
                </div>
              </>
            )}
          </>
        )}
        <div className="mt-20">
          Thank you again for the Registration.
        </div>
        <div>
          For any further inquiries, please contact the
          Registration & Submission Office.
        </div>
        <div className="mt-20 fw-500">Sincerely yours,</div>
        <div className="mt-20">
          APSAVD 2025 Congress Registration & Submission
          Office
          <br />
          Email:{' '}
          <a href="mailto:registration@apsavd2025.tw">
            registration@apsavd2025.tw
          </a>
          <br />
          Website:{' '}
          <a href="https://apsavd2025.tw/">
            https://apsavd2025.tw/
          </a>
        </div>
      </div>
    </div>
  );
};

export default Result;
