import { useEffect, useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import Input from '../Input';
import useFetch from '../../hooks/useFetch';

const PersonalInformation = ({
  isDisabled = false,
  values = {},
  setFieldValue,
  errors = {},
  touched = {},
  handleChange,
  isProfile = false,
}) => {
  const { data } = useFetch('/country');

  const isChineseLocale =
    values?.country &&
    (values.country?.label === 'Taiwan' ||
      values.country?.label === 'Hong Kong' ||
      values.country?.label === 'Macau' ||
      values.country?.label === 'China');

  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    // 判斷是否為手機
    const checkMobile = () => {
      setIsMobile(
        window.matchMedia('(max-width: 768px)').matches
      );
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setShowTooltip(false);
  };

  const handleClick = () => {
    if (isMobile) setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    if (values?.country?.value) {
      setFieldValue('countryCode', {
        label: values?.country?.code,
        value: values?.country?.code,
      });
    }
  }, [values?.country?.value]);

  return (
    <>
      <div className="form-group">
        <div className="form-item w-50">
          <label>
            Country<span className="require">*</span>
          </label>
          <Input
            id="country"
            type="select"
            options={data}
            value={values?.country}
            onChange={option => {
              setFieldValue('country', option);
              setFieldValue('countryCode', option?.code);
            }}
            invalid={errors.country && touched.country}
            disabled={isDisabled || isProfile}
          />
          <ErrorMessage
            error={errors.country}
            touched={touched.country}
          />
        </div>
        <div className="form-item w-50">
          <label>
            City of Institution/Organization
            <span className="require">*</span>
          </label>
          <Input
            id="city"
            type="text"
            value={values.city}
            onChange={handleChange}
            invalid={errors.city && touched.city}
            disabled={isDisabled}
          />
        </div>
        <ErrorMessage
          error={errors.city}
          touched={touched.city}
        />
      </div>
      <div className="form-group">
        <div className="form-item w-50">
          <label>
            Title<span className="require">*</span>
          </label>
          <Input
            id="title"
            type="select"
            options={[
              {
                label: 'Prof.',
                value: 'Prof.',
              },
              {
                label: 'Dr.',
                value: 'Dr.',
              },
              {
                label: 'Mr.',
                value: 'Mr.',
              },
              {
                label: 'Mrs.',
                value: 'Mrs.',
              },
              {
                label: 'Ms.',
                value: 'Ms.',
              },
            ]}
            value={values.title}
            onChange={option => {
              setFieldValue('title', option);
            }}
            invalid={errors.title && touched.title}
            disabled={isDisabled}
          />
          <ErrorMessage
            error={errors.title}
            touched={touched.title}
          />
        </div>
        <div className="form-item w-50">
          <label>
            Gender<span className="require">*</span>
          </label>
          <div
            className="radio-group"
            data-gender-error={
              errors.gender && touched.gender
                ? 'true'
                : 'false'
            }
          >
            {[
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
            ].map(option => (
              <label
                key={option.value}
                className="radio-label"
              >
                <input
                  type="radio"
                  name="gender"
                  value={option.value}
                  checked={values.gender === option.value}
                  onChange={handleChange}
                />
                {option.label}
              </label>
            ))}
            <ErrorMessage
              error={errors.gender}
              touched={touched.gender}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="form-item w-30">
          <label>
            First Name<span className="require">*</span>
          </label>
          <Input
            id="firstName"
            type="text"
            value={values.firstName}
            onChange={handleChange}
            invalid={errors.firstName && touched.firstName}
            disabled={isDisabled}
          />
          <ErrorMessage
            error={errors.firstName}
            touched={touched.firstName}
          />
        </div>
        <div className="form-item w-30">
          <label>Middle Name</label>
          <Input
            id="middleName"
            type="text"
            value={values.middleName}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className="form-item w-30">
          <label>
            Last (Family) Name
            <span className="require">*</span>
          </label>
          <Input
            id="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            invalid={errors.lastName && touched.lastName}
            disabled={isDisabled}
          />
          <ErrorMessage
            error={errors.lastName}
            touched={touched.lastName}
          />
        </div>
      </div>
      {isChineseLocale && (
        <div className="form-group">
          <div className="form-item w-100">
            <label>中文姓名</label>
            <Input
              id="chineseName"
              type="text"
              value={values.chineseName}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
      {values?.country?.label === 'Taiwan' && (
        <div className="form-group">
          <div className="form-item w-100">
            <label>
              身分證字號 <span className="require">*</span>
            </label>
            <Input
              id="idNumber"
              type="text"
              value={values.idNumber}
              onChange={handleChange}
              invalid={errors.idNumber && touched.idNumber}
              disabled={isDisabled}
            />
            <ErrorMessage
              error={errors.idNumber}
              touched={touched.idNumber}
            />
          </div>
        </div>
      )}
      <div className="form-group">
        <div className="form-item w-100">
          <label>
            Institution/Organization
            <span className="require">*</span>
          </label>
          <Input
            id="institution"
            type="text"
            value={values.institution}
            onChange={handleChange}
            invalid={
              errors.institution && touched.institution
            }
            disabled={isDisabled}
          />
          <ErrorMessage
            error={errors.institution}
            touched={touched.institution}
          />
        </div>
      </div>
      {isChineseLocale && (
        <div className="form-group">
          <div className="form-item w-100">
            <label>單位中文名稱</label>
            <Input
              id="institutionChineseName"
              type="text"
              value={values.institutionChineseName}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
      <div className="form-group">
        <div className="form-item w-10">
          <label>
            Contact Tel<span className="require">*</span>
          </label>
          <Input
            id="countryCode"
            type="select"
            options={data?.map(d => ({
              label: d.code,
              value: d.code,
            }))}
            value={values.countryCode}
            onChange={option => {
              setFieldValue('countryCode', option);
            }}
            invalid={
              errors.countryCode && touched.countryCode
            }
            disabled={isDisabled}
          />
        </div>
        <div className="form-item w-85">
          <label>&#12288;</label>
          <Input
            id="contactTel"
            type="text"
            value={values.contactTel}
            onChange={handleChange}
            invalid={
              errors.contactTel && touched.contactTel
            }
            disabled={isDisabled}
          />
          <ErrorMessage
            error={errors.contactTel}
            touched={touched.contactTel}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="form-item w-100">
          <label>
            Email<span className="require">*</span>
          </label>
          <div
            className="tooltip-icon"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            <Input
              id="email"
              value={values.email}
              onChange={handleChange}
              invalid={errors.email && touched.email}
              disabled={isDisabled || isProfile}
            />
            <div className="info-icon">
              {(isProfile || isDisabled) && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/register-icon-remind.svg`}
                  alt="Email Info"
                  onClick={toggleTooltip}
                />
              )}
              {(isProfile || isDisabled) && showTooltip && (
                <div className="tooltip">
                  If you no longer use the email address
                  associated with your APSAVD 2025 Congress
                  account, you may{' '}
                  <a
                    className="hover"
                    href="mailto:registration@apsavd2025.tw"
                  >
                    contact us
                  </a>{' '}
                  for help restoring access to your account.
                </div>
              )}
            </div>
          </div>
          {!isDisabled && !isProfile && (
            <label className="fz-12 color-gray">
              Your e-mail will be the login name of your
              account to the congress system. Please check
              your email for instruction to activate the
              account after you sign up.
            </label>
          )}
          <ErrorMessage
            error={errors.email}
            touched={touched.email}
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
