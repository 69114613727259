import { useState, useEffect } from 'react';
import Countdown from '../countdown/Countdown';
import './banner.scss';

const Banner = () => {
  const [styles, setStyles] = useState({});
  const updateStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1480 && screenWidth <= 1920) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.5}px`,
        maxWidth: `${50 + (1920 - screenWidth) * 0.03}%`,
      });
    } else if (screenWidth > 1920 && screenWidth <= 2240) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.2}px`,
      });
    } else if (screenWidth > 2240) {
      setStyles({
        maxWidth: `${800 - (1920 - screenWidth) * 0.4}px`,
        marginLeft: `${420 - (1920 - screenWidth) * 0.2}px`,
      });
    }
  };

  useEffect(() => {
    updateStyles();
    window.addEventListener('resize', updateStyles);
    return () =>
      window.removeEventListener('resize', updateStyles);
  }, []);

  return (
    <section className="home-banner">
      <div className="banner-logo">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.tas.org.tw/"
          className="logo-tas"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-tas.png`}
            alt="logo-tas"
          />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.apsavd.org/"
          className="logo-apsavd"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-apsavd.png`}
            alt="logo-apsavd"
          />
        </a>
      </div>
      <p
        className="home-banner-title"
        style={{
          maxWidth: styles.maxWidth,
          marginLeft: styles.marginLeft,
        }}
      >
        18th Asian Pacific Society of Atherosclerosis And
        Vascular Diseases Congress 2025
      </p>
      <Countdown />
    </section>
  );
};

export default Banner;
