import '../../assets/scss/form.scss';
import Input from '../Input';
import ErrorMessage from '../ErrorMessage';
import useFetch from '../../hooks/useFetch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// import usePost from '../../hooks/usePost';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaCheck, FaPlus } from 'react-icons/fa';
import Collapse from '../../components/Collapse';
import FileDropzone from '../../components/FileDropzone';
import { Editor } from '@tinymce/tinymce-react';

import { FiX } from 'react-icons/fi';
import AbstractContentEditor from './AbstractContentEditor';
// import FileThumbnail from '../FileThumbnail';

const SubmissionForm = ({
  values = {},
  handleChange,
  errors = {},
  touched = {},
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  id = null,
}) => {
  // console.log(values.content.background);
  const nav = useNavigate();
  const { token } = useContext(AuthContext);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth < 1024 && !isSmallScreen) {
        setIsSmallScreen(true);
        Swal.fire({
          text: 'For the best experience, please access this site on a desktop device.',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showClass: { popup: '' },
          hideClass: { popup: '' },
        });
      } else if (
        window.innerWidth >= 1024 &&
        isSmallScreen
      ) {
        setIsSmallScreen(false);
        Swal.close();
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [isSmallScreen]);

  const handleCancel = () => {
    Swal.fire({
      text: 'Would you kindly confirm if you would like to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        nav(-1);
      }
    });
  };

  // const { postData, success, error, data } =
  //   usePost('/submission');
  const { data: options } = useFetch(
    '/submission-category'
  );

  const handleUpdateSubmission = body => {
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/submission/${id}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
  };

  const handleUploadFile = ({ file, type }) => {
    // const { id: dataId } = data;
    const formData = new FormData();
    // formData.append('postId', data?.id || id);
    formData.append('type', type);
    formData.append('file', file);
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
  };

  const handleDeleteFile = url => {
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/delete-file`,
      {
        method: 'POST',
        body: JSON.stringify({
          url,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const deleteOrganization = institute => {
    setFieldValue(
      'instituteList',
      values.instituteList.filter(
        val => val.value !== institute.value
      )
    );

    // 連動刪除作者服務單位
    const authorList = values.authorList.map(author => ({
      ...author,
      instituteList: author.instituteList.filter(
        o => o.value !== institute.value
      ),
    }));

    setFieldValue('authorList', authorList);
  };

  const handleDeleteOrganization = institute => {
    Swal.fire({
      text: 'Are you sure you would like to delete it?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        deleteOrganization(institute);
      }
    });
  };

  const handleAddNewAuthor = () => {
    setFieldValue('authorList', [
      ...values.authorList,
      {
        name: '',
        email: '',
        instituteList: [],
      },
    ]);
  };

  const handleDeleteAuthor = author => {
    Swal.fire({
      text: 'Are you sure you would like to delete it?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        const newItems = values.authorList.filter(
          person => author !== person
        );
        setFieldValue('authorList', newItems);
      }
    });
  };

  const getFileName = url =>
    new URL(url).pathname.split('/').pop();

  return (
    <>
      <div className="form-container">
        <Collapse
          title="Abstract Submission System"
          requiredText="* = required field"
          isDefaultOpen={true}
        >
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Abstract Title
                <span className="require">*</span>
              </label>
              <Input
                id="abstractTitle"
                onChange={handleChange}
                value={values.abstractTitle}
                invalid={
                  errors.abstractTitle &&
                  touched.abstractTitle
                }
              />
              <ErrorMessage
                error={errors.abstractTitle}
                touched={touched.abstractTitle}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Category Selection
                <span className="require">*</span>
              </label>
              <Input
                type="select"
                options={options}
                onChange={option =>
                  setFieldValue('category', option)
                }
                value={values.category}
                invalid={
                  errors.category && touched.category
                }
              />
              <ErrorMessage
                error={errors.category}
                touched={touched.category}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Presentation Type
                <span className="require">*</span>
              </label>
              <div
                className="radio-group"
                data-presentationType-error={
                  errors.presentationType &&
                  touched.presentationType
                    ? 'true'
                    : 'false'
                }
              >
                {[
                  { label: 'Oral', value: 'Oral' },
                  { label: 'Poster', value: 'Poster' },
                  {
                    label: 'Either One',
                    value: 'Either One',
                  },
                ].map(option => (
                  <label
                    key={option.value}
                    className="radio-label"
                  >
                    <input
                      type="radio"
                      name="presentationType"
                      value={option.value}
                      checked={
                        values.presentationType ===
                        option.value
                      }
                      onChange={handleChange}
                    />
                    {option.label}
                  </label>
                ))}
                <ErrorMessage
                  error={errors.presentationType}
                  touched={touched.presentationType}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-85">
              <label>Institute / Organization List</label>
              <Input
                id="institute"
                onChange={handleChange}
                value={values.institute}
              />
            </div>
            <div className="form-item w-10">
              <button
                className="primary-btn fw-800 
								"
                onClick={() => {
                  if (!values.institute.trim()) {
                    return;
                  }
                  setFieldValue('instituteList', [
                    ...values.instituteList,
                    {
                      label: values.institute,
                      value: values.institute,
                    },
                  ]);
                  setFieldValue('institute', '');
                }}
              >
                + Add
              </button>
            </div>
            <div className="form-item w-100">
              <div className="institute">
                {values.instituteList?.map(
                  (institute, idx) => (
                    <div
                      className="institute-item"
                      key={idx}
                    >
                      <span>{institute.label}</span>
                      <FiX
                        onClick={() => {
                          handleDeleteOrganization(
                            institute
                          );
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="w-100">Author List</label>
            {values.authorList.map((author, idx) => (
              <div
                className="author-list-container"
                key={author.id || idx}
              >
                <label className="w-30 color-gray author-list">
                  Name<span className="require">*</span>
                  <ErrorMessage
                    error={errors.authorList?.[idx]?.name}
                    touched={
                      touched.authorList?.[idx]?.name
                    }
                  />
                </label>
                <label className="w-30 color-gray author-list">
                  Email<span className="require">*</span>
                  <ErrorMessage
                    error={errors.authorList?.[idx]?.email}
                    touched={
                      touched.authorList?.[idx]?.email
                    }
                  />
                </label>
                <label className="w-30 color-gray author-list">
                  Institute/Organization list
                  <span className="require">*</span>
                  <ErrorMessage
                    error={
                      errors.authorList?.[idx]
                        ?.instituteList
                    }
                    touched={
                      touched.authorList?.[idx]
                        ?.instituteList
                    }
                  />
                </label>

                <div className="w-30">
                  <Input
                    id={`authorList[${idx}]['name']`}
                    name={`authorList.${idx}.name`}
                    onChange={handleChange}
                    onBlur={() =>
                      setFieldTouched(
                        `authorList.${idx}.name`,
                        true
                      )
                    }
                    value={author.name}
                    invalid={
                      errors.authorList?.[idx]?.name &&
                      touched.authorList?.[idx]?.name
                    }
                  />
                </div>
                <div className="w-30">
                  <Input
                    id={`authorList.${idx}.email`}
                    onChange={handleChange}
                    onBlur={() =>
                      setFieldTouched(
                        `authorList.${idx}.email`,
                        true
                      )
                    }
                    value={author.email}
                    invalid={
                      errors.authorList?.[idx]?.email &&
                      touched.authorList?.[idx]?.email
                    }
                  />
                </div>
                <div className="w-30">
                  <Input
                    type="select"
                    options={values.instituteList}
                    isMulti
                    onChange={options => {
                      setFieldValue(
                        `authorList.${idx}.instituteList`,
                        options
                      );
                      setFieldTouched(
                        `authorList.${idx}.instituteList`,
                        true
                      );
                    }}
                    value={author.instituteList}
                    invalid={
                      !!errors.authorList?.[idx]
                        ?.instituteList &&
                      touched.authorList?.[idx]
                        ?.instituteList
                    }
                  />
                </div>
                {idx == 0 && (
                  <img
                    style={{ visibility: 'hidden' }}
                    src={`${process.env.PUBLIC_URL}/images/submission-icon-delete.svg`}
                    alt="Delete"
                  />
                )}
                {idx !== 0 && (
                  <img
                    src={
                      hoveredIndex === idx
                        ? `${process.env.PUBLIC_URL}/images/submission-icon-delete-active.svg`
                        : `${process.env.PUBLIC_URL}/images/submission-icon-delete.svg`
                    }
                    onClick={() =>
                      handleDeleteAuthor(author)
                    }
                    onMouseEnter={() =>
                      setHoveredIndex(idx)
                    }
                    onMouseLeave={() =>
                      setHoveredIndex(null)
                    }
                    alt="Delete"
                  />
                )}
              </div>
            ))}
            <button
              className="primary-btn author-btn fw-800"
              onClick={handleAddNewAuthor}
            >
              + Add Author
            </button>
          </div>
          <div className="form-group">
            <label>Abstract body text</label>
            <div className="form-item w-100">
              <div className="color-gray">
                Background<span className="require">*</span>
                <ErrorMessage
                  error={errors.content?.background}
                  touched={touched.content?.background}
                />
              </div>
              <AbstractContentEditor
                value={values.content.background}
                handleChange={content =>
                  setFieldValue(
                    'content.background',
                    content
                  )
                }
                onBlur={() =>
                  setFieldTouched(
                    'content.background',
                    true
                  )
                }
                error={errors.content?.background}
                touched={touched.content?.background}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <div className="color-gray">
                Method<span className="require">*</span>
                <ErrorMessage
                  error={errors.content?.method}
                  touched={touched.content?.method}
                />
              </div>
              <AbstractContentEditor
                value={values.content.method}
                handleChange={content =>
                  setFieldValue(
                    'content["method"]',
                    content
                  )
                }
                onBlur={() =>
                  setFieldTouched('content.method', true)
                }
                error={errors.content?.method}
                touched={touched.content?.method}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <div className="color-gray">
                Results<span className="require">*</span>
                <ErrorMessage
                  error={errors.content?.results}
                  touched={touched.content?.results}
                />
              </div>
              <AbstractContentEditor
                value={values.content.results}
                handleChange={content =>
                  setFieldValue(
                    'content["results"]',
                    content
                  )
                }
                onBlur={() =>
                  setFieldTouched('content.results', true)
                }
                error={errors.content?.results}
                touched={touched.content?.results}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <div className="color-gray">
                Conclusion<span className="require">*</span>
                <ErrorMessage
                  error={errors.content?.conclusion}
                  touched={touched.content?.conclusion}
                />
              </div>
              <AbstractContentEditor
                value={values.content.conclusion}
                handleChange={content =>
                  setFieldValue(
                    'content["conclusion"]',
                    content
                  )
                }
                onBlur={() =>
                  setFieldTouched(
                    'content.conclusion',
                    true
                  )
                }
                error={errors.content?.conclusion}
                touched={touched.content?.conclusion}
              />
            </div>
          </div>
        </Collapse>
        <div className="form-item mt-40">
          <Collapse
            title="Young Investigator Award Eligibility"
            requiredText="* = required field"
            isDefaultOpen={true}
            className={
              errors.cvFile && touched.cvFile
                ? 'error-border'
                : ''
            }
          >
            <div className="collapse-content">
              <p className="mx-10">
                • Applicant must submit the training
                certification and curriculum vitae.
              </p>
              <p className="mx-10">
                • Applicant must be aged 45 years or younger
                at the time of application (birth year after
                1980).
              </p>
              <p className="mx-10">
                • Applicant must be the first author of
                his/her abstract and a registered
                participant of the conference.
              </p>
              <p className="mx-10">
                • The presenter must join APSAVD 2025
                Congress as in-person participant to compete
                the award.
              </p>
              <p className="mx-10">
                • Abstracts will be shortlisted based on
                scores assigned by the Abstract Selection
                Committee.
              </p>
              <div
                className="checkbox-group fw-500 my-20"
                onClick={() =>
                  setFieldValue(
                    'yia',
                    values.yia === 'Attend'
                      ? 'Not Attend'
                      : 'Attend'
                  )
                }
              >
                <div
                  className={`checkbox-style ${
                    values.yia === 'Attend' ? 'checked' : ''
                  }`}
                >
                  {values.yia === 'Attend' && <FaCheck />}
                </div>
                <span>Attend</span>
              </div>

              {values.yia === 'Attend' && (
                <>
                  <label>
                    Curriculum Vitae File
                    <span className="require">*</span>
                  </label>
                  <FileDropzone
                    maxSize={10}
                    acceptType={{
                      '.pdf': [],
                      '.doc': [],
                      '.docx': [],
                    }}
                    setFieldValue={setFieldValue}
                    file={values.cvFile}
                    field={'cvFile'}
                  />

                  {typeof values.cvFile === 'string' && (
                    <a
                      href={values.cvFile}
                      download={getFileName(values.cvFile)}
                    >
                      Curriculum Vitae File
                    </a>
                  )}
                </>
              )}
            </div>
          </Collapse>
        </div>

        <div className="button-group">
          <button
            className="secondary-btn fw-800 mt-40"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn fw-800 mt-40"
            onClick={handleSubmit}
          >
            {id ? 'Save' : 'Create'}
          </button>
        </div>
      </div>
    </>
  );
};

export default SubmissionForm;
